import { api } from "@/api";
import request from "../../utils/request";

export default api('lab/user-material', {
    /**
     * 批量添加用户物资
     * @param data
     */
    saveBatch(data) {
        return request({
            url: `/api/lab/user-material/save-batch`,
            method: 'put',
            data: JSON.stringify(data)
        })
    },
})
