<template>
  <div :class="{'has-logo':showLogo}">
    <logo v-if="showLogo" :collapse="isCollapse"/>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
          :active-text-color="variables.menuActiveText"
          :background-color="variables.menuBg"
          :collapse="isCollapse"
          :collapse-transition="false"
          :default-active="activeMenu"
          :router="true"
          :text-color="variables.menuText"
          :unique-opened="false"
          mode="vertical"
      >
        <sidebar-item v-for="route in permissionRoutes" :key="route.path" :base-path="route.path" :item="route"/>
      </el-menu>
    </el-scrollbar>
  </div>
</template>
<style lang="scss" scoped>
#app .sidebar-container {
  width: 240px;
}
</style>
<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'

export default {
  components: { SidebarItem, Logo },
  computed: {
    ...mapGetters([
      'sidebar',
      'permissionRoutes'
    ]),
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    showLogo() {
      return this.$store.state.settings
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>
