import variables from '@/styles/element-variables.scss'
import defaultSettings from '@/settings'

const { showSettings, showTabs, showLogo, fixedHeader, showTheme } = defaultSettings

const state = {
    theme: variables.theme,
    showSettings: showSettings,
    showTabs: showTabs,
    showLogo: showLogo,
    fixedHeader: fixedHeader
}

const mutations = {
    CHANGE_SETTING: (state, { key, value }) => {
        if (state.hasOwnProperty(key)) {
            state[key] = value
        }
    }
}

const actions = {
    changeSetting({ commit }, data) {
        commit('CHANGE_SETTING', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

