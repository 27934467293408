<template>
  <div :class="{ 'collapse': collapse}" class="sidebar-logo-container">
    <transition name="sidebarLogoFade">
      <router-link
          v-if="collapse"
          key="collapse"
          class="sidebar-logo-link"
          to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo"/>
        <h1 v-else class="sidebar-title">{{ title }}</h1>
      </router-link>
      <router-link
          v-else
          key="filterExpand"
          class="sidebar-logo-link"
          style="text-align: left; padding-left: 12px"
          to="/">
        <img v-if="logo" :src="logo" class="sidebar-logo">
        <h1 class="sidebar-title">{{ title }}</h1>
      </router-link>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      logo: require('@/assets/logo6.png'),
      title: "物资设备管理系统"
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  z-index: 1002;
  width: 100%;
  height: 60px;
  line-height: 60px;
  //background-color: #2e76ee;
  background-color: #1159c5!important;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 32px;
      height: 32px;
      vertical-align: middle;
      margin-right: 10px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      font-size: 18px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0;
    }
  }
}
</style>
