import request from '@/utils/request'
import { api } from "@/api";
import {isArray} from "@/utils";

export default api('sys/user', {

    /**
     * 登录
     * @param data
     * @returns {AxiosPromise}
     */
    login: data => {
        return request({
            url: '/api/login',
            method: 'post',
            data: data
        })
    },

    /**
     * 登出
     * @returns {AxiosPromise}
     */
    logout: () => {
        return request({
            url: '/api/logout',
            method: 'get'
        })
    },

    /**
     * 加载用户信息
     * @param id
     */
    loadInfo(id) {
        return request({
            url: `/api/sys/user/select-info/${id}`,
            method: 'get'
        })
    },

    /**
     * 保存用户信息
     * @param data
     */
    saveInfo(data) {
        return request({
            url: `/api/sys/user/save-info`,
            method: 'put',
            data: JSON.stringify(data)
        })
    },

    /**
     * 批量保存用户信息
     * @param data
     */
    saveBatch(data) {
        return request({
            url: `/api/sys/user/save-batch`,
            method: 'put',
            data: JSON.stringify(data)
        })
    },

    /**
     * 更改密码
     * @param data
     */
    updatePassword(data) {
        return request({
            url: '/api/sys/user/update-password',
            method: 'put',
            params: data
        })
    },

    /**
     * 获取用户角色列表
     * @param page
     * @returns {AxiosPromise}
     */
    loadRoleList: () => {
        return request({
            url: `/api/sys/user/select-roles`,
            method: 'get'
        })
    },

    loadUserList(orgCode) {
        return request({
            url: `/api/sys/user/select-user-list/${orgCode}`,
            method: 'get'
        })
    },

    updateCode(id) {
        return request({
            url: `/api/sys/user/update-code/${id}`,
            method: 'get'
        })
    },
    updateOrgCodeById(id, orgcode) {
        return request({
            url: `/api/sys/user/update-orgcode/${orgcode}/${id}`,
            method: 'get'
        })
    },
    updateStatuById(id, num) {
        return request({
            url: `/api/sys/user/update-statu/${id}/${num}`,
            method: 'get'
        })
    },
    /**
     *将人员选择物资类型，型号进行关联
     */
    pushUserWare(params) {
        return request({
            url: '/api/sys/user/push-ware',
            method: 'post',
            data: JSON.stringify(params)
        })
    },
    exportList(orgCode, userType) {
        return request({
            url: `/api/sys/user/exportList/${orgCode}/${userType}`,
            method: 'get',
            responseType: 'blob'
        })
    },

})
