import { api } from "@/api";
import request from "../../utils/request";

export default api('lab/retrieval', {
    submit(data) {
        return request({
            url: `/api/lab/retrieval/submit`,
            method: 'post',
            data: JSON.stringify(data)
        })
    },
})
