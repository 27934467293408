<template>
  <div>
    <image-viewer
        v-if="showViewer && imagePreview"
        :url-list="imagePreviewUrlList"
        :current="previewIndex"
        :z-index="zIndex"
        :options="options"
        :on-close="closeViewer"
        :on-switch="switchViewer"/>
    <video-viewer
        v-if="showViewer && videoPreview"
        :url-list="videoPreviewUrlList"
        :current="previewIndex"
        :z-index="zIndex"
        :options="options"
        :on-close="closeViewer"
        :on-switch="switchViewer">
    </video-viewer>
  </div>
</template>

<script>
import ImageViewer from "./image-viewer";
import VideoViewer from "./video-viewer";
import { isImage, isVideo, openFile } from "../utils";

let prevOverflow = '';
export default {
  name: "AppDocumentPreview",
  props: {
    currentUrl: String,
    previewUrlList: {
      type: Array,
      default: () => []
    },
    zIndex: {
      type: Number,
      default: 2000
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    ImageViewer,
    VideoViewer
  },
  computed: {
    previewIndex() {
      let previewIndex = 0;
      const { previewUrlList } = this
      const currentIndex = previewUrlList.indexOf(this.currentUrl);
      if (currentIndex >= 0) {
        previewIndex = currentIndex;
      }
      return previewIndex;
    },
    preview() {
      const { previewUrlList } = this
      return Array.isArray(previewUrlList) && previewUrlList.length > 0;
    },
    imagePreview() {
      return isImage(this.currentUrl)
    },
    imagePreviewUrlList() {
      const { previewUrlList } = this
      return previewUrlList.filter(url => isImage(url))
    },
    videoPreview() {
      return isVideo(this.currentUrl)
    },
    videoPreviewUrlList() {
      const { previewUrlList } = this
      return previewUrlList.filter(url => isVideo(url))
    },
  },
  data() {
    return {
      showViewer: false
    }
  },
  methods: {
    switchViewer(index) {
    },
    openViewer() {
      // don't show viewer when preview is false
      if (!this.preview) {
        return;
      }
      if (!isImage(this.currentUrl) && !isVideo(this.currentUrl)) {
        openFile(this.currentUrl);
        return;
      }
      // prevent body scroll
      prevOverflow = document.body.style.overflow;
      document.body.style.overflow = 'hidden';
      this.showViewer = true;
    },
    closeViewer() {
      document.body.style.overflow = prevOverflow;
      this.showViewer = false;
    }
  }
}
</script>

<style scoped>

</style>