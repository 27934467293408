import store from '@/store'
import router from '@/router';
import { MessageBox } from "element-ui";
import { IMClient } from './IMClient'
import { codeTypes } from './IMCode'

const client = new IMClient();
const { on, off, emit } = client.handler
on('read', ({ socket, data }) => {
    
    const msg = JSON.parse(data)
    const type = codeTypes[msg.code];
    if (type) emit(type, msg)
})

on('logout', msg => {
    
    MessageBox.alert('请重新登录，' + msg.message, '登录异常', {
        confirmButtonText: '确定',
        type: 'warning'
    }).then(() => {
        store.dispatch('user/logout').then(() => {
            router.push(`/login`)
        })
    })
})
const im = {
    on,
    off,
    emit
}
export default im
