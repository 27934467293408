import { api } from "@/api";
import request from "@/utils/request";

export default api('sys/role', {

    /**
     * 获取角色列表
     * @param page
     * @returns {AxiosPromise}
     */
    loadRoleList: () => {
        return request({
            url: `/api/sys/role/select-roles`,
            method: 'get'
        })
    },

})
