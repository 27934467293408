import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout'
import AppMain from '@/layout/components/AppMain'
import store from "@/store";
import { routerMode } from '@/settings'
import { adminRoutes } from './modules/admin'
import { userRoutes } from './modules/user'


Vue.use(Router);

const routerPush = Router.prototype.push
Router.prototype.push = function push(localhost) {
    return routerPush.call(this, localhost).catch(err => console.log(err))
}
export const constRoutes = [
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                component: () => import('@/views/redirect/index')
            }
        ]
    },
    {
        path: '/error',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/error/:status',
                component: () => import('@/views/error/index.vue')
            }
        ]
    },
    {
        path: '/login',
        name: '登录',
        component: () => import('@/views/login/index')
    }
]

export const asyncRoutes = []

/**
 * 创建路由
 * @returns {VueRouter}
 */
const createRouter = () => new Router({
    // view: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: constRoutes
})

/**
 * 更新路由
 * @returns {VueRouter}
 */
export const updateRouter = (data = []) => {
    if (routerMode === 'remote') {
        asyncRoutes.splice(0, asyncRoutes.length)
        const find = (child = {}, parent = []) => {
            const route = {
                path: child.rootPath,
                name: child.functionName,
                meta: {
                    title: child.functionName,
                    icon: child.functionIcon,
                    noCache: false,
                    roles: child.functionPermission ? child.functionPermission.split(',') : []
                },
                hidden: child.functionHidden === '是',
            }
            if (child.functionComponent === 'Layout') {
                route.component = Layout
                route.redirect = child.redirectPath
            } else if (child.functionComponent === 'AppMain') {
                route.component = AppMain
                route.redirect = child.redirectPath
            } else {
                const s = child.functionComponent.replace('@/', '');
                route.component = () => import('@/' + s + '.vue')
                const paths = parent.map(child => child.path)
                route.meta.activeMenu = paths.join('/')
                if (paths.includes('/')) {
                    route.meta.affix = true
                }
            }
            if (child.sysFunctions && child.sysFunctions.length > 0) {
                route.children = child.sysFunctions.map(child => find(child, [...parent, route]))
            }
            return route
        }
        let routes = data.map(v => find(v));
        asyncRoutes.push(...routes)
    } else {
        asyncRoutes.splice(0, asyncRoutes.length)
        asyncRoutes.push(...userRoutes)
        if (store.getters.userAccount === 'admin') {
            asyncRoutes.push(...adminRoutes)
        }
    }
}

/**
 * 重置路由
 */
export const resetRouter = () => {
    let _router = createRouter();
    router.matcher = _router.matcher
}
const router = createRouter()

export default router
