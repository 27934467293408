import { api } from "@/api";
import request from "@/utils/request";
export default api('file/read', {

    /**
     * 查询文件预览
     * @param id
     */
    getFileRead(id) {
        return request({
            url: `/api/file/read/getFileRead/${id}`,
            method: 'get'
        })
    },

})
