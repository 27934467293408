/**
 * @file validate
 * @author itsnows <xue.com.fei@gmail.com>
 * @since 2021/1/10 上午10:17
 */
/**
 * 是否是外部路径
 * @param s
 * @returns {boolean}
 */
export function isExternal(s) {
    return /^(https?:|mailto:|tel:)/.test(s)
}

/**
 * 是否是URL
 * @param {string} s
 * @returns {boolean}
 */
export function isUrl(s) {
    const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
    return reg.test(s)
}

/**
 * 是否是数字
 * @param {string} s
 * @returns {boolean}
 */
export function isNumber(s) {
    return !isNaN(Number(s));
}

/**
 * 是否是邮箱
 * @param {string} s
 * @returns {boolean}
 */
export function isEmail(s) {
    const reg = /^(([^<>()[]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return reg.test(s)
}

/**
 * 是否是字符串
 * @param s
 * @returns {boolean}
 */
export function isString(s) {
    if (typeof s === 'string' || s instanceof String) {
        return true
    }
    return false
}

/**
 * 是否是小写字母
 * @param {string} s
 * @returns {boolean}
 */
export function isLowerCase(s) {
    const reg = /^[a-z]+$/
    return reg.test(s)
}

/**
 * 是否是大写字母
 * @param {string} s
 * @returns {boolean}
 */
export function isUpperCase(s) {
    const reg = /^[A-Z]+$/
    return reg.test(s)
}

/**
 * 是否是字母
 * @param {string} s
 * @returns {boolean}
 */
export function isAlphabets(s) {
    const reg = /^[A-Za-z]+$/
    return reg.test(s)
}

/**
 * 是否是数组
 * @param o
 * @returns {boolean}
 */
export function isArray(o) {
    if (typeof Array.isArray === 'undefined') {
        return Object.prototype.toString.call(o) === '[object Array]'
    }
    return Array.isArray(o)
}

