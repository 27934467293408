/**
 * @file token
 * @author itsnows <xue.com.fei@gmail.com>
 * @since 2020/4/25 下午2:31
 */
import storage from "@/utils/storage";

const token = {

    /**
     * 授权令牌名称
     * @type {string}
     */
    name: 'Authorization',

    /**
     * 获取授权令牌
     * @param key
     * @returns {*}
     */
    get: (key = token.name) => storage.getItem(key),

    /**
     * 设置授权令牌
     * @param value
     * @param key
     * @returns {*}
     */
    set: (value, key = token.name) => storage.setItem(key, value),

    /**
     * 移除授权令牌
     * @param key
     *  @returns {*}
     */
    remove: (key = token.name) => storage.removeItem(key)

}

export default token
