import { api } from "@/api";
import request from "../../utils/request";
import {isArray} from "@/utils";

export default api('lab/purchase-list', {
    /**
     * 批量采购清单
     * @param data
     */
    saveBatch(data) {
        return request({
            url: `/api/lab/purchase-list/save-batch`,
            method: 'put',
            data: JSON.stringify(data)
        })
    },

    selectPageByUserId: (data) => {
        return request({
            url: `/api/lab/purchase-list/select-page-by-userId`,
            method: 'get',
            params:data
        })
    },
    createUserInfo:(id,purchId) =>{
        return request({
            url: `/api/lab/purchase-list/create-user/${id}/${purchId}`,
            method: 'get'
        })
    },
    loadExcelReport:(id) =>{
        return request({
            url: `/api/lab/purchase-list/excel-report/${id}`,
            method: 'get'
        })
    },
    loadExcelReportCurrent:(id) =>{
        return request({
            url: `/api/lab/purchase-list/excel-report-current/${id}`,
            method: 'get'
        })
    },
    loadExcelReportXCurrent:(id) =>{
        return request({
            url: `/api/lab/purchase-list/excel-report-shoe/${id}`,
            method: 'get'
        })
    },
    deleteList:(purchId) =>{
        return request({
            url: `/api/lab/purchase-list/delete-list/${purchId}`,
            method: 'get'
        })
    },
    loadPlanList: (params = {
        index: 0,
        limit: 10
    }) => {
        return request({
            url: `/api/lab/purchase-list/select-plan-page`,
            method: 'get',
            params
        })
    },
    loadAddressList: (params = {
        index: 0,
        limit: 10
    }) => {
        return request({
            url: `/api/lab/purchase-list/select-address-page`,
            method: 'get',
            params
        })
    },
    loadFlowWareList:
        (params = {
            index: 0,
            limit: 10
        }) => {
            let like
            if (params.like && isArray(params.like)) {
                like = params.like.map(v => `like=${v}`).join('&')
                delete  params.like
            }
            return request({
                url: `/api/lab/purchase-list/select-ware-flow${like ? '?' + like : ''}`,
                method: 'get',
                params
            })
        },


})
