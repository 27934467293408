import { api } from "@/api";
import request from "../../utils/request";

export default api('physical', {
    /**
     * 查看个人体检记录
     * @returns {AxiosPromise}
     */
    selectPersonalPage: (data) => {
        return request({
            url: `/api/physical/select-personal-page`,
            method: 'get',
            params:data
        })
    },

})
