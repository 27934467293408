import { api } from "@/api";
import request from "../../utils/request";

export default api('lab/procure-plan', {
    checkedState(Id){
        return request({
            url: `/api/lab/procure-plan/checked-state-plan/${Id}`,
            method: 'get',
        })
    },
    finishState(Id){
        return request({
            url: `/api/lab/procure-plan/finish-fill-plan/${Id}`,
            method: 'get',
        })
    },
    updateProReviewerStateId(Id){
        return request({
            url: `/api/lab/procure-plan/update-reviewer-state/${Id}`,
            method: 'get',
        })
    },
    insertAndUpdate(data){
        return request({
            url: `/api/lab/procure-plan/insert-update`,
            method: 'post',
            data: JSON.stringify(data)
        })
    },
})
