<template>
  <div>
    <el-button v-for="item in actionItems"
               :key="item.name"
               size="small"
               type="text"
               @click="handleAction(item.name)">{{ item.label }}
    </el-button>
  </div>
</template>

<script>
import { isString } from "@/utils/validate";

const ACTION_ITEMS = [
  {
    name: 'preview',
    label: '查看'
  },
  {
    name: 'edit',
    label: '编辑'
  },
  {
    name: 'delete',
    label: '删除'
  }
]
export default {
  name: "AppTableColumnAction",
  props: {
    preview: {
      type: Boolean,
      default: true
    },
    roles: {
      type: Array,
      default: function () {
        return []
      }
    },
    row: {
      type: Object,
      default: function () {
        return {}
      }
    },
    editable: {
      type: Boolean,
      default: function () {
        return true
      }
    }
  },
  data() {
    return {
      actionItems: [],
    }
  },
  methods: {
    handleAction(event) {
      this.$emit('onAction', event, this.row)
    },
    initAction() {

      this.actionItems.splice(0, this.actionItems.length)
      if (this.preview) {
        this.actionItems.push(ACTION_ITEMS[0])
      }
      if (this.roles.includes('admin') ||
          this.roles.includes('write')) {
        if (this.editable) {
          this.actionItems.push(ACTION_ITEMS[1])
          this.actionItems.push(ACTION_ITEMS[2])
        }
        return
      }
      if (this.roles.includes('edit') &&
          this.editable) {
        this.actionItems.push(ACTION_ITEMS[1])
      }
      if (this.roles.includes('delete') &&
          this.editable) {
        this.actionItems.push(ACTION_ITEMS[2])
      }
      this.roles.forEach(v => {
        if (!isString(v) && v.name && v.label) {
          this.actionItems.push(v)
        }
      })
    },
  },
  created() {
    this.initAction()
  },
  watch: {
    editable() {
      this.initAction()
    },
    roles() {
      this.initAction()
    }
  }
}
</script>

<style scoped>
</style>
