import request from '@/utils/request'

import { isArray } from "@/utils";
/**
 * 基础接口
 * @param pathVariable
 * @param prototype
 * @returns {BaseApi}
 */
export function api(pathVariable, prototype = {}) {
    function BaseApi() {

        /**
         * 路径变量
         */
        this.pathVariable = pathVariable

        /**
         * 创建信息
         * @returns {AxiosPromise}
         */
        this.createInfo = () => {
            return request({
                url: `/api/${this.pathVariable}/create`,
                method: 'get'
            })
        }

        /**
         * 加载信息
         * @param id
         * @returns {AxiosPromise}
         */
        this.loadInfo = id => {
            return request({
                url: `/api/${this.pathVariable}/select/${id}`,
                method: 'get'
            })
        }

        /**
         * 加载列表
         * @param params
         * @returns {AxiosPromise}
         */
        this.loadList = (params = {
            index: 0,
            limit: 10
        }) => {
            let like
            if (params.like && isArray(params.like)) {
                like = params.like.map(v => `like=${v}`).join('&')
                delete  params.like
            }
            return request({
                url: `/api/${this.pathVariable}/select-page${like ? '?' + like : ''}`,
                method: 'get',
                params
            })
        }

        /**
         * 保存信息
         * @param data
         * @returns {AxiosPromise}
         */
        this.saveInfo = data => {
            return request({
                url: `/api/${this.pathVariable}/save`,
                method: 'post',
                data: JSON.stringify(data)
            })
        }

        /**
         * 删除信息
         * @param id
         * @returns {AxiosPromise}
         */
        this.deleteInfo = id => {
            return request({
                url: `/api/${this.pathVariable}/delete/${id}`,
                method: 'delete'
            })
        }

        /**
         * 删除列表
         * @param ids
         * @returns {AxiosPromise}
         */
        this.deleteList = ids => {
            return request({
                url: `/api/${this.pathVariable}/delete-ids/${ids}`,
                method: 'delete'
            })
        }
    }

    return Object.assign(new BaseApi(), prototype)
}
