<template>
  <div id="appbar"
       :style="fixed ? 'min-height:54px' : ''"
       class="appbar">
    <el-row id="toolbar"
            :class="{ 'toolbar-fixed' : fixed }"
            :style="{ 'width' : fixedWidth }">
      <el-row style="display: flex">
        <el-button v-for="item in actionItems.filter( v => v.fixed === 'left' )"
                   :key="item.name"
                   :icon="item.icon"
                   :type="item.theme ? item.theme : 'primary'"
                   class="btn-action"
                   plain
                   style="height: 36px"
                   @click="handleAction(item)">{{ item.label }}
        </el-button>
        <div
            style="display: flex; flex: auto; flex-direction: row; align-content: space-around; flex-wrap: wrap; justify-content: end; row-gap:10px; margin-left: 10px">
          <el-button v-for="item in actionItems.filter( v => v.fixed === 'right' )"
                     v-show=" layout === 'view' "
                     :key="item.name"
                     :icon="item.icon"
                     class="btn-action"
                     style="display: flex;"
                     @click="handleAction(item)">{{ item.label }}
          </el-button>
          <div v-for="item in selectItems"
               v-show="layout==='list'"
               :key="item.id" style="margin-left: 1%;">
            <div v-if="item.type !== undefined && item.type==='radio'"  style="margin-top: 3%;">
            <el-radio-group v-model="item.value" >
              <el-radio v-for="option in item.options"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value"
                        @change="handleSelect">{{ option.label }}</el-radio>
            </el-radio-group>
            </div>
            <el-input  v-else-if="item.type !== undefined && item.type === 'text'" v-model="item.value" :placeholder="item.label" @change="handleSelect"></el-input>
            <el-date-picker
                v-else-if="item.type !== undefined && item.type === 'time'"
                v-model="item.value"
                type="datetimerange"
                format="yyyy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                :start-placeholder="item.label"
                :end-placeholder="item.label"
                @change="handleSelect">
            </el-date-picker>
            <el-date-picker
                v-else-if="item.type !== undefined && item.type === 'year'"
                v-model="item.value"
                type="year"
                format="yyyy"
                value-format="yyyy"
                @change="handleSelect"
                placeholder="选择选择年份">
            </el-date-picker>
            <el-cascader v-else-if="item.options && item.options.filter(v => Boolean(v.children)).length > 0"
                         v-model="item.value"
                         :clearable="item.clearable !== false"
                         :options="item.options"
                         :placeholder="item.label"
                         :props="{ checkStrictly: item.checkStrictly !== false }"
                         :show-all-levels="true"
                         filterable
                         style="margin-right: 10px; width: 220px; display: flex; float: right;"
                         @change="handleSelect">
            </el-cascader>
            <el-cascader
                v-else-if="
								item.options &&
								item.options.filter((v) => Boolean(v.sysOrgs))
									.length > 0
							"
                v-model="item.value"
                :clearable="item.clearable !== false"
                :options="item.options"
                :placeholder="item.label"
                :props="{
								  checkStrictly: item.checkStrictly !== false,
								  checkStrictly: true,
									children: 'sysOrgs',
									hasChildren: 'hasChildren',
									label: 'orgName',
									value: 'orgCode',
							}"
                :show-all-levels="true"
                filterable
                style="
								margin-right: 10px;
								width: 220px;
								display: flex;
								float: right;
							"
                @change="handleSelect"
            >
            </el-cascader>
            <el-select v-else
                       v-model="item.value"
                       :clearable="item.clearable !== false"
                       :placeholder="item.label"
                       style="margin-left: 10px;width: 180px;"
                       value-key="value"
                       @change="handleSelect">
              <el-option
                  v-for="option in item.options"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value">
              </el-option>
            </el-select>
          </div>
          <el-input v-show="layout==='list' && search.show !== false"
                    v-model="searchValue"
                    :placeholder="search.placeholder ? search.placeholder : '请输入内容'"
                    clearable
                    search
                    style="width: 220px; margin-left:10px; float:right;"
                    v-on:clear="handleSearch(searchValue)">
          </el-input>
          <el-button
              v-show="layout==='list' && search.show !== false"
              class="btn-action"
              icon="el-icon-search"
              plain
              style="border-radius: 0 6px 6px 0;margin-left: -2px;z-index: 2"
              type="primary"
              @click="handleSearch(searchValue)"></el-button>
          <el-button v-if="filters.length > 0"
                     v-show="layout==='list'"
                     class="btn-action"
                     icon="el-icon-coordinate"
                     plain
                     type="primary"
                     @click="handleFilterExpand">筛选
          </el-button>
        </div>
      </el-row>
      <el-row v-show="filterExpand"
              class="toolbar-expand">
        <el-row>
          <el-col :span="20">
            <div style="display: flex;width: 535px">
              <el-input v-model="filterValue"
                        class="input-with-select"
                        clearable
                        placeholder="请输入内容"
                        style="margin-right: 10px;">
                <el-select slot="prepend"
                           v-model="filterField"
                           placeholder="请选择条件"
                           style="width: 180px;"
                           value-key="id">
                  <el-option v-for="item in filters"
                             :key="item.id"
                             :label="item.label"
                             :value="item"></el-option>
                </el-select>
                <!-- <el-button icon="el-icon-close" slot="append"></el-button> -->
              </el-input>
              <el-button :disabled="!filterAdded" icon="el-icon-plus" @click="handleTagAdd"></el-button>
            </div>
          </el-col>
          <el-col :span="4">
            <div style="float:right">
              <div style="display: flex;float: right">
                <el-button icon="el-icon-search" @click="handleFilterSearch">搜索
                </el-button>
                <el-button icon="el-icon-refresh-left" @click="handleFilterRest">重置
                </el-button>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-tag
              v-for="item in filterItems"
              v-cloak
              :key="item.id"
              :disable-transitions="true"
              closable
              style="margin-top: 16px"
              @close="handleTagRemove(item)">
            {{ item.label + ' : ' + item.value }}
          </el-tag>
        </el-row>
      </el-row>
    </el-row>
  </div>
</template>

<script>
import { isString } from "@/utils";
import { mapState } from 'vuex'

export const permissions = [
  {
    no: 0,
    name: 'refresh',
    icon: 'el-icon-refresh-right',
    label: '刷新',
    fixed: 'left'
  },
  {
    no: 1,
    name: 'preview',
    icon: 'el-icon-view',
    label: '预览',
    fixed: 'left'
  },
  {
    no: 2,
    name: 'edit',
    icon: 'el-icon-edit',
    label: '编辑',
    fixed: 'left'
  },
  {
    no: 3,
    name: 'add',
    icon: 'el-icon-plus',
    label: '新增',
    fixed: 'left'
  },
  {
    no: 4,
    name: 'delete',
    icon: 'el-icon-delete',
    label: '删除',
    fixed: 'left',
    theme: 'danger'
  },
  {
    no: 5,
    name: 'save',
    icon: 'el-icon-finished',
    label: '保存',
    fixed: 'left'
  },
  {
    no: 6,
    name: 'import',
    icon: 'el-icon-upload2',
    label: '导入',
    fixed: 'left'
  },
  {
    no: 7,
    name: 'export',
    icon: 'el-icon-download',
    label: '导出',
    fixed: 'left'
  }
]

export default {
  name: "AppBar",
  props: {
    roles: {
      type: Array,
      default: function () {
        return []
      }
    },
    filters: {
      type: Array,
      default: function () {
        return []
      }
    },
    selects: {
      type: Array,
      default: function () {
        return []
      }
    },
    search: {
      type: Object,
      default: function () {
        return {}
      }
    },
    layout: {
      type: String,
      default: function () {
        return 'list'
      }
    },
    editable: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data() {
    return {
      actionItems: [],
      selectItems: [],
      searchValue: null,
      filterItems: [],
      filterField: null,
      filterValue: null,
      filterAdded: false,
      filterExpand: false,
      fixed: false,
      fixedWidth: 'auto'
    }
  },
  computed: {
    ...mapState({
      opened: state => state.app.sidebar.opened
    }),
    size() {
      return 'medium'
    },
  },
  methods: {
    handleAction(event) {
      this.$emit('onAction', event.name)
    },
    handleTextSelect(){

    },
    handleSearch(event) {
      this.$emit('onSearch', event)
    },
    handleSelect(event) {
      this.$emit(
          'onSelect',
          this.selectItems
              .filter((v) => v.value)
              .reduce((pv, cv) => {
                if (cv.type !== undefined && cv.type === 'time'){
                  return {
                    ...pv,
                    [cv.id]: Array.isArray(cv.value)
                        ? cv.value[0] + ',' + cv.value[1]
                        : cv.value,
                  }
                }else if (cv.type !== undefined && cv.type === 'date'){
                  return {
                    ...pv,
                    [cv.id]: Array.isArray(cv.value)
                        ? cv.value[0] + ',' + dateFormat(cv.value[1], 'YYYY-MM-DD 23:59:59')
                        : cv.value,
                  }
                }
                else{
                  return {
                    ...pv,
                    [cv.id]: Array.isArray(cv.value)
                        ? cv.value.slice(-1)[0]
                        : cv.value,
                  }
                }
              }, {})
      )
      // this.$emit('onSelect', this.selectItems.filter(v => v.value).reduce((pv, cv) => {
      //   return { ...pv, [cv.id]: Array.isArray(cv.value) ? cv.value.slice(-1)[0] : cv.value }
      // }, {}))
    },
    handleTagAdd(event) {
      this.filterItems.push({
        id: this.filterField.id,
        label: this.filterField.label,
        value: this.filterValue
      })
      this.filters.splice(this.filters.findIndex((v) => v.id === this.filterField.id), 1)
      this.filterField = null;
      this.filterValue = null;
    },
    handleTagRemove(event) {
      this.filterItems.splice(this.filterItems.findIndex((v) => v.id === event.id), 1)
      this.filters.push({
        id: event ? event.id : undefined,
        label: event.label,
        value: event.value,
      })
    },
    handleFilterExpand(event) {
      this.filterExpand = !this.filterExpand;
    },
    handleFilterSearch(event) {
      this.$emit('onFilter', this.filterItems.filter(v => v.value).reduce((pv, cv) => {
        return { ...pv, [cv.id]: cv.value }
      }, {}))
    },
    handleFilterRest(event) {
      this.filterItems.forEach(v => this.filters.push({
        id: v.id,
        label: v.label,
        value: v.value,
      }))
      this.filterItems.splice(0, this.filterItems.length)
    },
    initAction() {

      this.actionItems.splice(0, this.actionItems.length)
      this.actionItems.push(permissions[0])
      if (this.layout === 'list') {
        if (this.roles.includes('admin') ||
            this.roles.includes('write')) {
          this.actionItems.push(permissions[3])
          this.actionItems.push(permissions[4])
        } else if (this.roles.includes('add')) {
          this.actionItems.push(permissions[3])
        } else if (this.roles.includes('delete')) {
          this.actionItems.push(permissions[4])
        }
        if (this.roles.includes('import')) {
          this.actionItems.push(permissions[6])
        }
        if (this.roles.includes('export')) {
          this.actionItems.push(permissions[7])
        }
        this.roles.forEach((v, i) => {
          if (!isString(v) && v.name && v.icon && v.label) {
            v.no = permissions.length + i + 1
            this.actionItems.push(v)
          }
        })
      } else {
        if (this.roles.includes('admin') ||
            this.roles.includes('write') ||
            this.roles.includes('edit') ||
            this.roles.includes('add')) {
          if (this.editable) {
            this.actionItems.push(permissions[1])
            this.actionItems.push(permissions[5])
          } else {
            this.actionItems.push(permissions[2])
          }
        }
        this.roles.forEach((v, i) => {
          if (!isString(v) && v.name && v.icon && v.label) {
            if (this.editable || v.affix) {
              v.no = permissions.length + i + 1
              this.actionItems.push(v)
            }
          }
        })
      }
    },
    initSelect() {
      this.selectItems = this.selects;
      this.selectItems.forEach(v => v.options ? v.options.forEach(v1 => v1.id = v.id) : v.options)
    },
    initWidth() {
      this.fixedWidth = this.fixed ? this.opened ?
          'calc(100% - 240px)' : 'calc(100% - 54px)' : 'auto'
    }
  },
  watch: {
    filterField() {
      this.filterAdded = this.filterField && this.filterValue;
    },
    filterValue() {
      this.filterAdded = this.filterField && this.filterValue;
    },
    editable() {
      if (this.layout === 'view') {
        this.initAction()
      }
    },
    roles() {
      if (this.layout === 'list' ||
          this.layout === 'view') {
        this.initAction()
      }
    },
    selects() {
      this.initSelect()
    },
    opened() {
      this.initWidth()
    }
  },
  created() {
    this.initAction()
    this.initSelect()
  },
  mounted() {
    const app = document.querySelector('#app');
    app.addEventListener('scroll', () => {
      let scrollTop = app.pageYOffset || app.scrollTop
      let fixed = scrollTop > 16
      if (!this.fixed && fixed) {
        this.fixed = fixed
        // this.$nextTick(function () {
        //   let w = document.querySelector('#appbar').clientWidth
        //   this.fixedWidth = (w + 32) + 'px'
        //   this.fixed = fixed
        // })
        return
      }
      if (this.fixed && !fixed) {
        this.fixed = fixed
      }
      this.initWidth()
    })
  }
}
</script>

<style lang="scss">

.el-tag--light {
  margin-right: 10px;
}

.el-tabs__item {
  color: #909399;
}

.el-table thead {
  color: #C0C4CC;
}

.el-button + .el-button {
  margin-left: 10px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.input-with-select .el-input-group__append {
  background-color: #fff;
}

.el-table td, .el-table th {
  padding: 10px 0 !important;
}

.el-select-dropdown .el-scrollbar .el-scrollbar__wrap {
  overflow: scroll !important;
}

.el-button--primary {
  background: #2e76ee;
}

</style>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.appbar {
  position: relative;

  .toolbar {
    .btn-action {
      &:hover {
        opacity: 0.9;
      }
    }
  }

  .toolbar-fixed {
    z-index: 2001;
    position: fixed;
    margin-left: -32px;
    margin-top: -32px;
    padding: 6px 16px;
    background: #fff;
    box-shadow: 0 1px 4px rgb(0 21 41 / .08);
    border-radius: 0 0 4px 4px;
    transition: padding .25s ease-out;
  }

  .toolbar-expand {
    margin-top: 16px;
    background: #e9ecf1;
    padding: 16px;
    border-radius: 4px;
    transition: all .25s;
  }
}

</style>
