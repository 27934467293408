import { api } from "@/api";
import request from '@/utils/request'
import { isArray } from "@/utils";

export default api('lab/storage-list', {
    saveBatch(data) {
        return request({
            url: `/api/lab/storage-list/save-batch`,
            method: 'put',
            data: JSON.stringify(data)
        })
    },
    /**
     * 读取物资配件关联信息
     * @returns {AxiosPromise}
     */
    loadTreeList: (params = {
        index: 0,
        limit: 10
    }) => {
        let like
        if (params.like && isArray(params.like)) {
            like = params.like.map(v => `like=${v}`).join('&')
            delete  params.like
        }
        return request({
            url: `/api/lab/storage-list/load-tree-list${like ? '?' + like : ''}`,
            method: 'get',
            params
        })
    },
})
