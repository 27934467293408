import { api } from "@/api";
import request from "../../utils/request";

export default api('hazard/info', {
    /**
     * 根据OrgCode获取数据
     * @returns {AxiosPromise}
     * @param params
     */
    getDataListByOrgCode: (data) => {
        return request({
            url: `/api/hazard/info/get-data-list-by-orgcode`,
            method: 'get',
            params:data
        })
    },
})
