import { api } from "@/api";
import request from "../../utils/request";
import {isArray} from "@/utils";

export default api('lab/material', {
    /**
     * 获取行政地区树列表
     * @returns {AxiosPromise}
     */
    findByMatNoList: (data) => {
        return request({
            url: `/api/lab/material/find-by-matno-list`,
            method: 'get',
            params:data
        })
    },

    /**
     * 获取行政地区树列表
     * @returns {AxiosPromise}
     */
    findByPLNOList: (data) => {
        return request({
            url: `/api/lab/material/find-by-plno-list`,
            method: 'get',
            params:data
        })
    },
    /**
     * 获取选项
     * @returns
     */
    getMateType() {
        return request({
            url: '/api/lab/material/select-type-item',
            method: 'get',
        })
    },
    /**
     * 根据物资类型获取选项
     * @returns
     */
    loadItemsList: (data) => {
        return request({
            url: `/api/lab/material/select-items-type`,
            method: 'get',
            params:data
        })
    },
    loadWareList:
        (params = {
            index: 0,
            limit: 10
        }) => {
            let like
            if (params.like && isArray(params.like)) {
                like = params.like.map(v => `like=${v}`).join('&')
                delete  params.like
            }
        return request({
            url: `/api/lab/material/select-ware-process${like ? '?' + like : ''}`,
            method: 'get',
            params
        })
    },
    loadModuleList:
        (params = {
            index: 0,
            limit: 10
        }) => {
            let like
            if (params.like && isArray(params.like)) {
                like = params.like.map(v => `like=${v}`).join('&')
                delete  params.like
            }
            return request({
                url: `/api/lab/material/select-module-page${like ? '?' + like : ''}`,
                method: 'get',
                params
            })
        },
    /**
     *根据物资类型获取物资颜色
     */
    getMateColor(params) {
        return request({
            url: '/api/lab/material/select-color-type',
            method: 'post',
            data: JSON.stringify(params)
        })
    },
    /**
     * 根据物资主键和配件编码删除配件关联
     */
    deleteModule(params) {
        return request({
            url: '/api/lab/material/delete-module',
            method: 'post',
            data: JSON.stringify(params)
        })
    },
    /**
     * 根据物资主键和配件编码添加配件关联
     */
    addModule(params) {
        return request({
            url: '/api/lab/material/add-module',
            method: 'post',
            data: JSON.stringify(params)
        })
    },
    /**
     * 读取物资配件关联信息
     * @returns {AxiosPromise}
     */
    loadTreeList: (params = {
        index: 0,
        limit: 10
    }) => {
        let like
        if (params.like && isArray(params.like)) {
            like = params.like.map(v => `like=${v}`).join('&')
            delete  params.like
        }
        return request({
            url: `/api/lab/material/load-tree-list${like ? '?' + like : ''}`,
            method: 'get',
            params
        })
    },

    saveBatch(data) {
        return request({
            url: `/api/lab/material/save-batch`,
            method: 'put',
            data: JSON.stringify(data)
        })
    },

})
