<script>
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  render(h, context) {
    const { icon, title } = context.props
    const vnodes = []

    if (icon) {
      vnodes.push(  <svg class="icon svg-icon" aria-hidden="true" style="width:1.2em;height:1.2em;">
        <use href={['#'+icon]}></use>
      </svg>)
      //vnodes.push(<svg class="icon" aria-hidden="true"><use xlink:href={['#'+icon]}></use></svg>)
      // vnodes.push(<i class={[icon, 'sub-el-icon']} style="width:18px"/>)
    }

    if (title) {
      vnodes.push(<span slot='title'>{(title)}</span>)
    }
    return vnodes
  }
}
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
  font-size: 18px
}
/*.icon {*/
/*  width: 1em;*/
/*  height: 1em;*/
/*  vertical-align: -0.15em;*/
/*  fill: currentColor;*/
/*  overflow: hidden;*/
/*}*/
</style>
