const getters = {
    sidebar: state => state.app.sidebar,
    device: state => state.app.device,
    size: state => state.app.size,
    isAuthorization: state => state.auth.isAuthorization,
    rolesCredentials: state => state.auth.rolesCredentials,
    userId: state => state.user.userId,
    userName: state => state.user.userName,
    userOrgName: state => state.user.userOrgName,
    userAvatar: state => state.user.userAvatar,
    userStatus: state => state.user.userStatus,
    userAccount: state => state.user.userAccount,
    userRoles: state => state.user.userRoles,
    userMenus: state => state.user.userMenus,
    userRoleCode: state => state.user.userRoleCode,
    userRoleName: state => state.user.userRoleName,
    cachedTabs: state => state.tabs.cachedTabs,
    visitedTabs: state => state.tabs.visitedTabs,
    permissionRoutes: state => state.permission.routes,
    dictionaryOptions: state => state.dictionary.options
}
export default getters
