/**
 * 未知消息
 */
const CODE_UNKNOWN = 0x99;

/**
 * 心跳包
 */
const CODE_HEARTBEAT = 0x01;

/**
 * 登录
 */
const CODE_LOGIN = 0x40;

/**
 * 登出
 */
const CODE_LOGOUT = 0x41;

/**
 * 消息代码
 * @type {{}}
 */
const codes = {
    CODE_UNKNOWN,
    CODE_HEARTBEAT,
    CODE_LOGIN,
    CODE_LOGOUT
}

/**
 * 消息代码类型
 * @type {{}}
 */
const codeTypes = Object.keys(codes).reduce((types, key) => {
    types[codes[key]] = key.slice(5, key.length).toLowerCase()
    return types
}, {})
module.exports = {
    ...codes,
    codeTypes
}
