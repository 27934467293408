<template>
  <div>
    <el-upload
        ref="file"
        :accept="fileAccept"
        :action="fileAction"
        :before-upload="handleBeforeUpload"
        :class="{ hide : fileDisabled }"
        :data="fileQuery"
        :disabled="fileDisabled"
        :file-list="fileItems"
        :limit="fileLimit"
        :on-change="handleChange"
        :on-error="handleError"
        :on-exceed="handleExceed"
        :on-preview="handlePreview"
        :on-progress="handleProgress"
        :on-remove="handleRemove"
        :on-success="handleSuccess"
        list-type="picture-card"
        multiple
        name="file">
      <i class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <el-progress
            v-if="file.status === 'uploading'"
            :percentage="parseInt(file.percentage ? file.percentage : 0)"
            type="circle"></el-progress>
        <el-image
            v-else
            :src="file.url"
            alt=""
            class="el-upload-list__item-thumbnail"
            style="width: 100px; height: 100px">
          <div slot="placeholder" class="image-slot" style="text-align: center;line-height: 100px;">
            <el-image :src="require('@/assets/images/ic_file_placeholder.png')"></el-image>
          </div>
          <div slot="error" class="image-slot" style="text-align: center;line-height: 100px;">
            <el-image :src="require('@/assets/images/ic_file_error.png')"></el-image>
          </div>
        </el-image>
        <span v-if="isShow" class="el-upload-list__item-actions">
               <span
                   class="el-upload-list__item-preview"
                   @click="handlePreview(file)">
                 <i :class="isVideo(file.type) ? 'el-icon-video-play' : 'el-icon-zoom-in'"></i>
               </span>
               <span
                   class="el-upload-list__item-delete"
                   @click="handleDownload(file)"
               >
                 <i class="el-icon-download"></i>
               </span>
               <span
                   v-if="!disabled"
                   class="el-upload-list__item-delete"
                   @click="handleRemove(file)"
               >
                 <i class="el-icon-delete"></i>
               </span>
             </span>
      </div>
    </el-upload>
    <el-dialog :before-close="handlePreviewClose"
               :title="file.name"
               :visible.sync="filePreview"
               append-to-body
               class="upload-preview-dialog">
      <div class="preview">
        <div v-if="isVideo(file.type)"
             id="player-con"
             class="prism-player"
             style="height: 480px">
        </div>
        <el-image v-else
                  :preview-src-list="fileItems.map(v => v.url)"
                  :src="file.url"
                  alt=""
                  fit="contain"
                  style="width:100%; height: 480px;">
          <div slot="placeholder" class="image-slot" style="text-align: center;line-height: 100px;">
            <el-image :src="require('@/assets/images/ic_file_placeholder.png')"></el-image>
          </div>
          <div slot="error" class="image-slot" style="text-align: center;line-height: 100px;">
            <el-image :src="require('@/assets/images/ic_file_error.png')"></el-image>
          </div>
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
    <div class="el-button" @click="handlePreviewClose">关 闭</div>
    <div class="el-button el-button--primary" @click="handleDownload(file)">下载文件</div>
  </span>
    </el-dialog>
    <el-dialog title="验证授权码"  width="30%" top="16%" :visible.sync="verificationFormVisible">
      <el-form ref="ruleForm" :model="verificationForm" :rules="rules" >
        <el-form-item label="授权码" label-width="120px" prop="verificationCode">
          <el-input v-model="verificationForm.verificationCode" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="verificationFormVisible = false" >取 消</el-button>
        <el-button type="primary"  @click="submitCode">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { fileApi,downloadApi } from "@/api";
import { Message } from "element-ui";
import { Base64 } from "js-base64";

export default {
  name: "AppUpload",
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    otcId: {
      type: String,
      default() {
        return ''
      }
    },
    otcType: {
      type: String,
      default() {
        return ''
      }
    },
    fileLimit: {
      type: Number,
      default() {
        return 9
      }
    },
    fileSize: {
      type: Number,
      default() {
        // 200M
        return 200
      }
    },
    fileAccept: {
      type: String,
      default() {
        return '.jpeg,.jpg,.png,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.mp3,.wav,.mp4'
      }
    },
    filePath: {
      type: String,
      default() {
        return null
      }
    },
    fileAlias: {
      type: String,
      default() {
        return null
      }
    },
    type: {
      type: String,
      default() {
        return null
      }
    },
    isShow: {
      type: Boolean,
      default() {
        return true
      }
    },
    fileSource: {
      type: String,
      default() {
        return 'Server'
      }
    }
  },
  model: {
    prop: 'filePath',
    event: 'change'
  },
  data() {
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入授权码'));
      }  else {
        callback();
      }
    };
    return {
      verificationForm: {
        verificationCode: ''
      },
      verificationFile:null,
      fileAction: `/api/file/upload`,
      fileURL: require('@/settings').fileURL,
      filePreview: false,
      fileItems: [],
      fileQuery: {},
      fileCreated: false,
      verificationFormVisible: false,
      file: {},
      player: null,
      rules: {
        verificationCode: [
          { validator: validateCode, trigger: 'blur' }
        ],
      }
    }
  },
  created() {
    this.fileCreated = true
    this.initFile(true)
  },
  mounted() {
  },
  methods: {
    handleBeforeUpload(file) {

      const type = file.name.substring(file.name.lastIndexOf('.'))
      const size = file.size / 1024 / 1024
      if (!this.fileAccept.includes(type)) {
        Message({
          message: `文件格式只支持${this.fileAccept.split(',').join(' ')}！`,
          type: 'error',
          duration: 3 * 1000
        })
        return false
      }
      if (size > this.fileSize) {
        Message({
          message: `文件大小不能超过${this.fileSize}M！`,
          type: 'error',
          duration: 3 * 1000
        })
        return false
      }
      return true
    },
    handleRemove(file, fileList) {
      if (file.status === 'success') {
        this.deleteFile(file.id)
        if (!fileList) {
          this.fileItems.splice(this.fileItems.findIndex(v => v.uid === file.uid), 1)
        }
      }
    },
    handleExceed(files, fileList) {
      Message({
        message: '文件超出限制！',
        type: 'error',
        duration: 3 * 1000
      })
    },
    handlePreview(file) {
      this.file = file
      // window.open('http://192.168.0.155:8012/onlinePreview?url='+encodeURIComponent(Base64.encode(file.rawUrl)));
      this.filePreview = true
      if (this.isVideo(file.type)) {
        setTimeout(() => {
          this.initPlayer()
          this.player.loadByUrl(file.rawUrl)
        }, 1000)
      }
    },
    handlePreviewClose() {
      this.filePreview = false
      if (this.player) {
        this.player.dispose()
        this.player = null
      }
    },
    handleSuccess(res, file, fileList) {
      if (res.success) {
        const { data } = res
        file.id = data.id
        file.name = data.fileName
        file.type = data.fileType
        file.path = data.filePath
        file.alias = data.fileAlias
        file.size = data.fileSize
        file.url = this.getThumbnail(data.fileType, data.filePath)
        file.rawUrl = this.isUrl(data.filePath) ? data.filePath :
            this.fileURL + data.filePath
        if(this.type){
          this.$emit('uploadSuccess',data)
        }
      } else {
        fileList.splice(fileList.findIndex(v => v.uid === file.uid), 1)
        Message({
          message: res.message,
          type: 'error',
          duration: 3 * 1000
        })
      }
    },
    handleError(err, file, fileList) {
      fileList.splice(fileList.findIndex(v => v.uid === file.uid), 1)
      Message({
        message: err.message,
        type: 'error',
        duration: 3 * 1000
      })
    },
    submitCode(){
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          downloadApi.findGenerateCode(this.verificationForm.verificationCode.trim()).then(res => {
            if (res.success) {
              if(res.data == "验证成功"){
                this.handlePreviewClose()
                let link = document.createElement('a');
                link.href = this.verificationFile.rawUrl;
                link.download = this.verificationFile.name;
                link.target = '_blank';
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                link = null;
                this.verificationForm.verificationCode = ''
                this.verificationFormVisible = false
              }else{
                Message({
                  message: res.data,
                  type: 'error',
                  duration: 3 * 1000
                })
              }
            } else {
              Message({
                message: res.message,
                type: 'error',
                duration: 3 * 1000
              })
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleDownload(file) {
      if(["physicalReport", "PhysicalExamination", "MonFIle", "reviewReport"].includes(this.otcType)){
        this.$emit('editShow', 'edit');
        this.verificationFile = file
        this.verificationFormVisible = true

      }else{
        this.handlePreviewClose()
        let link = document.createElement('a');
        link.href = file.rawUrl;
        link.download = file.name;
        link.target = '_blank';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); 
        link = null;
      }


    },
    handleProgress(event, file, fileList) {
      file.percentage = event.loaded / event.total * 100
      console.log('handleProgress:' + event.loaded + '/' + event.total + ' , ' + file.percentage + '%')
    },
    handleChange(file, fileList) {
      this.fileItems = fileList;
    },
    initFile(flag) {
      this.fileQuery = {
        otcId: this.otcId,
        otcType: this.otcType,
        fileSource: this.fileSource
      }
      if (this.fileName) {
        this.fileQuery.fileAlias = this.fileName
      }
      if (this.fileCreated && flag) {
        this.loadFile()
      }
    },
    loadFile() {
      fileApi.select(this.otcId, this.otcType).then(res => {
        const { data } = res
        this.fileItems = data.map(v => {
          return {
            id: v.id,
            name: v.fileName,
            type: v.fileType,
            path: v.filePath,
            alias: v.fileAlias,
            size: v.fileSize,
            url: this.getThumbnail(v.fileType, v.filePath),
            rawUrl: this.formatUrl(this.isUrl(v.filePath) ? v.filePath :
                this.fileURL + v.filePath)
          }
        })
      })
    },
    deleteFile(id) {
      fileApi.delete(id).then(res => {
      })
    },
    initPlayer() {
      this.player = new Aliplayer({
            id: "player-con",
            width: "100%",
            height: "480px",
            autoplay: true,
            isLive: false,
            rePlay: false,
            playsinline: true,
            preload: true,
            controlBarVisibility: "hover",
            useH5Prism: true
          }, function (err) {
          }
      );
    },
    isUrl(url) {
      const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
      return reg.test(url)
    },
    isVideo(type) {
      switch (type) {
        case 'mp4':
        case 'avi':
        case 'm3u8':
          return true
        default:
          return false
      }
    },
    formatDuration(input) {
      function format(i) {
        const n = parseInt(i)
        return n < 10 ? '0' + n : n
      }

      let millis = parseFloat(input)
      const second = millis % 60
      millis = millis / 60
      const minute = millis % 60
      millis = millis / 60
      const hour = millis % 60
      return format(hour) + ':' + format(minute) + ':' + format(second)
    },
    formatUrl(url) {
      return `${url}?id=${new Date().getTime()}${Math.random()}`
    },
    getThumbnail(type, path) {
      let url;
      switch (type) {
        case 'doc':
        case 'docx':
          url = require('@/assets/images/word.png')
          break
        case 'ppt':
        case 'pptx':
          url = require('@/assets/images/ppt.png')
          break
        case 'xls':
        case 'xlsx':
          url = require('@/assets/images/xls.png')
          break
        case 'pdf':
          url = require('@/assets/images/pdf.png')
          break
        case 'exe':
          url = require('@/assets/images/ic_file_exe.png')
          break
        case 'dmg':
          url = require('@/assets/images/ic_file_dmg.png')
          break
        case 'chm':
          url = require('@/assets/images/ic_file_chm.png')
          break
        case 'css':
          url = require('@/assets/images/ic_file_css.png')
          break
        case 'csv':
          url = require('@/assets/images/ic_file_csv.png')
          break
        case 'code':
          url = require('@/assets/images/ic_file_code.png')
          break
        case 'txt':
          url = require('@/assets/images/ic_file_txt.png')
          break
        case 'mp3':
        case 'wav':
          url = require('@/assets/images/ic_file_music.png')
          break
        case 'mp4':
        case 'avi':
        case 'm3u8':
          url = require('@/assets/images/ic_file_video.png')
          break
        case 'raw':
        case 'zip':
          url = require('@/assets/images/ic_file_zip.png')
          break
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'ico':
          if (this.isUrl(path)) {
            url = this.formatUrl(path);
          } else {
            url = this.formatUrl(this.fileURL + path)
          }
          break
        default:
          url = require('@/assets/images/ic_file_unknown.png')
          break
      }
      return url
    }
  },
  computed: {
    fileDisabled() {
      return this.disabled || this.fileItems.length >= this.fileLimit
    },
    fileName() {
      return this.fileAlias ? `${this.fileAlias}-${this.fileItems.length}` : null
    }
  },
  watch: {
    otcId(v) {
      this.initFile(true)
    },
    otcType(v) {
      this.initFile(true)
    },
    filePath(v) {
      this.initFile()
    },
    fileAlias(v) {
      this.initFile()
    },
    fileSource(v) {
      this.initFile()
    },
    fileItems(v) {
      this.$emit("change", v.length === 0 ? null : v.map(v => v.path).join(','))
    }
  }
}
</script>
<style lang="scss">
.el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  //transition: none !important;
}

.el-progress-circle {
  height: 72px !important;
  width: 72px !important;
  margin-left: 22% !important;
}

.hide .el-upload--picture-card {
  display: none;
}

.el-image-viewer__mask, html body[class*=vab-theme-] .el-image-viewer__mask {
  background: #000000;
  opacity: 1.0;
}

</style>
<style lang="scss" scoped>
.upload-preview-dialog {
  .preview {
    text-align: center;
  }

  .el-button {
    cursor: pointer !important;
    padding: 10px 20px;
    font-size: 14px;
    color: #606266 !important;
    background: #FFFFFF !important;
    border: 1px solid #DCDFE6 !important;
    border-radius: 4px;
    -webkit-appearance: none !important;

    &:hover {
      opacity: 0.9;

      &:before,
      &:after {
        opacity: 0.75;

      }
    }
  }

  .el-button--primary {
    cursor: pointer !important;
    color: #FFFFFF !important;
    background-color: #1890ff !important;
    border-color: #1890ff !important;
  }
}
</style>
