import token from "@/utils/token";

import { IS_AUTHORIZATION, ROLES_CREDENTIALS } from '@/store/mutation-types'

export default {
    state: {
        isAuthorization: !!token.get(),
        rolesCredentials: {
            roles: {},
            credentials: {}
        }
    },
    /*getters: {
        isAuthorization: state => state.isAuthorization,
        rolesCredentials: state => state.rolesCredentials,
    },*/
    mutations: {
        [IS_AUTHORIZATION]: (state, isAuthorization) => state.isAuthorization = isAuthorization,
        [ROLES_CREDENTIALS]: (state, rolesCredentials) => state.rolesCredentials = rolesCredentials
    }
}
