import { api } from "@/api";
import request from "@/utils/request";

export default api('download/code', {
    /**
     * 生成一授权码字符串
     * @returns {AxiosPromise}
     * @param params
     */
    generateCode: () => {
        return request({
            url: `/api/download/code/generateCode`,
            method: 'get'
        })
    },
    /**
     * 获取授权码字符串
     * @returns {AxiosPromise}
     * @param params
     */
    findGenerateCode: (code) => {
        return request({
            url: `/api/download/code/find-generateCode/${code}`,
            method: 'get'
        })
    },
})