import Layout from "@/layout";

/**
 * @file 普通用户
 * @author itsnows <xue.com.fei@gmail.com>
 * @since 2021/12/23 上午10:05
 */
export const userRoutes = [
    {
        path: '/',
        name: '首页',
        redirect: '/index',
        children: [
            {
                path: 'index',
                name: '首页',
                meta: {
                    title: '首页',
                    icon: 'icon-a-home1-line',
                    affix: true
                },
                component: () => import('@/views/index/index'),
            }
        ],
        component: Layout
    }
]
