import { api } from "@/api";
import request from "@/utils/request";

export default api('hazard/factor', {
    /**
     * 获取面板数据
     * @returns {AxiosPromise}
     * @param params
     */
    loadIndexData: () => {
        return request({
            url: `/api/hazard/factor/select-index-data`,
            method: 'get'
        })
    },
})
