<template>
  <div class="app-tabs">
      <el-tabs v-model="activeTab"
               :class="{ [`app-tabs-content-${themes[theme]}`]: true }"
               class="app-tabs-content"
               type="card"
               @tab-click="handleTabClick"
               @tab-remove="handleTabRemove">
        <el-tab-pane v-for="view in visitedTabs"
                     :key="view.path"
                     :closable="!isAffix(view)"
                     :name="view.path">
        <span slot="label"
              style="display: inline-block;"
              @contextmenu.prevent="openMenu($event, view)">
          <app-icon :icon="view.meta.icon"
                    iconfont
                    style="margin-right:3px;">
          </app-icon>
          <span>{{ view.title }}</span>
        </span>
        </el-tab-pane>
      </el-tabs>
      <el-dropdown @command="handleCommand"
                   @visible-change="handleVisibleChange">
      <span :class="{'app-tabs-more-active' : activeMore}"
            :style="theme === 2 ? 'bottom: -8px;':''"
            class="app-tabs-more">
        <span class="app-tabs-more-icon">
          <i class="box box-t"></i>
          <i class="box box-b"></i>
        </span>
      </span>
        <template #dropdown>
          <el-dropdown-menu class="tabs-more">
            <el-dropdown-item command="refreshActiveTab">
              <span>刷新</span>
            </el-dropdown-item>
            <el-dropdown-item command="closeActiveTab">
              <span>关闭</span>
            </el-dropdown-item>
            <el-dropdown-item command="closeOtherTabs">
              <span>关闭其他</span>
            </el-dropdown-item>
            <el-dropdown-item command="closeRightTabs">
              <span>关闭右侧</span>
            </el-dropdown-item>
            <el-dropdown-item command="closeAllTabs">
              <span>关闭全部</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <ul v-if="visible"
          :style="{ left: left + 'px', top: top + 'px' }"
          class="contextmenu el-dropdown-menu el-popper tabs-more el-dropdown-menu--medium">
        <li class="el-dropdown-menu__item"
            @click="refreshActiveTab(selectTab)">
          <span>刷新</span>
        </li>
        <li class="el-dropdown-menu__item"
            @click="closeActiveTab(selectTab)">
          <span>关闭</span>
        </li>
        <li class="el-dropdown-menu__item"
            @click="closeOtherTabs(selectTab)">
          <span>关闭其他</span>
        </li>
        <li class="el-dropdown-menu__item"
            @click="closeRightTabs(selectTab)">
          <span>关闭右侧</span>
        </li>
        <li class="el-dropdown-menu__item"
            @click="closeAllTabs(selectTab)">
          <span>关闭全部</span>
        </li>
      </ul>
    </div>
</template>

<script>
import path from 'path'

export default {
  name: 'AppTabs',
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      affixTabs: [],
      selectTab: {},
      activeTab: '',
      activeMore: false,
      theme: 2,
      themes: ["card", "smart", "smooth"]
    }
  },
  computed: {
    routes() {
      return this.$store.state.permission.routes
    },
    visitedTabs() {
      return this.$store.state.tabs.visitedTabs
    },
    activeView() {
      return this.visitedTabs.find(v => v.path === this.activeTab)
    }
  },
  watch: {
    $route() {
      this.addTabs()
      this.toActiveTab()
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  },
  mounted() {
    this.initTabs()
    this.addTabs()
  },
  methods: {
    handleTabClick(event) {
      const view = this.visitedTabs[event.index]
      if (!this.isActive(view)) {
        this.$router.push({
          path: view.path,
          query: view.query,
          fullPath: view.fullPath
        })
      }
    },
    handleTabRemove(event) {
      const view = this.visitedTabs.find(v => v.path === event)
      this.closeActiveTab(view)
    },
    handleVisibleChange(event) {
      this.activeMore = event
    },
    handleCommand(event) {
      this[event].call(this, this.activeView)
    },
    toLastTab(views, view) {
      const topView = views.slice(-1)[0]
      if (topView) {
        this.$router.push(topView.fullPath)
      } else {
        if (view.name === '/index') {
          this.$router.replace({ path: '/redirect' + view.fullPath })
        } else {
          this.$router.push('/')
        }
      }
    },
    toActiveTab() {
      this.$nextTick(() => {
        for (const view of this.visitedTabs) {
          if (view.path === this.$route.path) {
            if (view.fullPath !== this.$route.fullPath) {
              this.$store.dispatch('tabs/updateVisitedTab', this.$route)
            }
            break
          }
        }
      })
    },
    refreshActiveTab(view) {
      view = view || this.selectTab
      this.$store.dispatch('tabs/delCachedTab', view).then(() => {
        const { fullPath } = view
        this.$nextTick(() => {
          this.$router.replace({
            path: '/redirect' + fullPath
          })
        })
      })
    },
    closeActiveTab(view) {
      this.$store.dispatch('tabs/delTab', view).then(({ visitedTabs }) => {
        if (this.isActive(view)) {
          this.toLastTab(visitedTabs, view)
        }
      })
    },
    closeOtherTabs(view) {
      this.$router.push(view)
      this.$store.dispatch('tabs/delOthersTabs', view).then(() => {
        this.toActiveTab()
      })
    },
    closeRightTabs(view) {
      this.$router.push(view)
      this.$store.dispatch('tabs/delRightsTabs', view).then(() => {
        this.toActiveTab()
      })
    },
    closeAllTabs(view) {
      this.$store.dispatch('tabs/delAllTabs').then(({ visitedTabs }) => {
        if (this.affixTabs.some(tab => tab.path === view.path)) return
        this.toLastTab(visitedTabs, view)
      })
    },
    openMenu(e, tag) {
      const rect = this.$el.getBoundingClientRect()
      const offsetLeft = rect.left
      const offsetWidth = this.$el.offsetWidth
      const maxLeft = Math.round(offsetWidth)
      const left = e.clientX - offsetLeft + 15
      if (left > maxLeft) {
        this.left = maxLeft
      } else {
        this.left = left
      }
      this.top = Math.round(rect.top - 20)
      this.visible = true
      this.selectTab = tag
    },
    closeMenu() {
      this.visible = false
    },
    isActive(view) {
      return view.path === this.$route.path
    },
    isAffix(view) {
      return view.meta && view.meta.affix
    },
    initTabs() {
      const affixTabs = this.affixTabs = this.filterAffixTags(this.routes)

      for (const tab of affixTabs) {
        if (!tab.name || !tab.path) continue
        this.$store.dispatch('tabs/addVisitedTab', tab)
        this.activeTab = tab.path
      }
    },
    addTabs() {
      const { name, path } = this.$route
      if (!name || !path) return false
      this.$store.dispatch('tabs/addTab', this.$route)
      this.activeTab = path
    },
    filterAffixTags(routes, basePath = '/') {
      let tabs = []
      routes.forEach(route => {
        if (route.meta && route.meta.affix) {
          const viewPath = path.resolve(basePath, route.path)
          tabs.push({
            fullPath: viewPath,
            path: viewPath,
            name: route.name,
            meta: { ...route.meta }
          })
        }
        if (route.children) {
          const tempTabs = this.filterAffixTags(route.children, route.path)
          if (tempTabs.length >= 1) {
            tabs = [...tabs, ...tempTabs]
          }
        }
      })
      return tabs
    }
  }
}
</script>
<style lang="scss" scoped>
.app-tabs {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-right: 16px;
  padding-left: 16px;
  user-select: none;
  background: #fff;

  ::v-deep {
    .fold-unfold {
      margin-right: 16px;
    }

    [class*='ri'] {
      margin-right: 3px;
    }
  }

  &-content {
    width: calc(100% - 32px);

    ::v-deep {
      height: 36px;

      .el-tabs__nav-next,
      .el-tabs__nav-prev {
        line-height: 36px;
      }

      .el-tabs__header {
        border-bottom: 0;

        .el-tabs__item {
          height: 36px;
          line-height: 36px;
        }
      }
    }

    &-card {
      ::v-deep {
        height: 36px;

        .el-tabs__nav-next,
        .el-tabs__nav-prev {
          height: 36px;
          line-height: 36px;
        }

        .el-tabs__header {
          border-bottom: 0;

          .el-tabs__nav {
            border: 0;
          }

          .el-tabs__item {
            box-sizing: border-box;
            height: 36px;
            margin-right: 10px;
            line-height: 36px;
            border: 1px solid #dcdfe6 !important;
            border-radius: 2.5px;
            transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;

            &.is-active {
              color: #1890ff;
              background: mix(#fff, #1890ff, 90%);
              border: 1px solid #1890ff !important;
              outline: none;
            }

            &:hover {
              border: 1px solid #1890ff !important;
            }
          }
        }
      }
    }

    &-smart {
      height: 36px;

      ::v-deep {
        .el-tabs__nav-next,
        .el-tabs__nav-prev {
          height: 36px;
          line-height: 36px;
        }

        .el-tabs__header {
          border-bottom: 0;

          .el-tabs__nav {
            border: 0;
          }

          .el-tabs__item {
            height: 36px;
            line-height: 36px;
            margin-right: 10px;
            outline: none;
            transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;

            &.is-active {
              background: mix(#fff, #1890ff, 90%);
              outline: none;

              &:after {
                width: 100%;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
              }
            }

            &:after {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 0;
              height: 2px;
              content: '';
              background-color: #1890ff;
              transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
            }

            &:hover {
              background: mix(#fff, #1890ff, 90%);

              &:after {
                width: 100%;
                transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border 0s, color 0.1s, font-size 0s;
              }
            }
          }
        }
      }
    }

    &-smooth {
      height: 36px + 4;

      ::v-deep {
        .el-tabs__nav-next,
        .el-tabs__nav-prev {
          height: 36px + 4;
          line-height: 36px + 4;
          bottom: 0;
        }

        .el-tabs__header {
          border-bottom: 0;

          .el-tabs__nav {
            border: 0;
          }

          .el-tabs__item {
            height: 36px + 4;
            padding: 0 30px 0 30px;
            margin-top: (50px - 36px - 4)/2;
            margin-right: -18px;
            line-height: 36px + 4;
            text-align: center;
            border: 0;
            outline: none;
            transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;

            &.is-active {
              padding: 0 30px 0 30px;
              color: #1890ff;
              background: mix(#fff, #1890ff, 90%);
              outline: none;
              mask: url('~@/assets/images/bg_tab.png');
              mask-size: 100% 100%;

              &:hover {
                padding: 0 30px 0 30px;
                color: #1890ff;
                background: mix(#fff, #1890ff, 90%);
                mask: url('~@/assets/images/bg_tab.png');
                mask-size: 100% 100%;
              }
            }

            &:hover {
              padding: 0 30px 0 30px;
              color: #515a6e;
              background: #dee1e6;
              mask: url('~@/assets/images/bg_tab.png');
              mask-size: 100% 100%;
            }
          }
        }
      }
    }
  }

  .contextmenu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    transform-origin: center top;
  }

  &-more {
    position: relative;
    bottom: -4px;

    &-active,
    &:hover {
      &:after {
        position: absolute;
        bottom: -1px;
        left: 0;
        height: 0;
        content: '';
      }

      .app-tabs-more-icon {
        transform: rotate(90deg);

        .box-t {
          &:before {
            transform: rotate(45deg);
          }
        }

        .box:before,
        .box:after {
          background: #1890ff;
        }
      }
    }

    &-icon {
      display: inline-block;
      color: #9a9a9a;
      cursor: pointer;
      transition: transform 0.3s ease-out;

      .box {
        position: relative;
        display: block;
        width: 14px;
        height: 8px;

        &:before {
          position: absolute;
          top: 0;
          left: 0px;
          width: 6px;
          height: 6px;
          content: '';
          background: #9a9a9a;
        }

        &:after {
          position: absolute;
          top: 0;
          left: 8px;
          width: 6px;
          height: 6px;
          content: '';
          background: #9a9a9a;
        }
      }

      .box-t {
        &:before {
          transition: transform 0.3s ease-out 0.3s;
        }
      }
    }
  }
}
</style>

