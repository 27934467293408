import { api } from "@/api";
import request from "@/utils/request";

export default api('physical/detail', {
    /**
     * 获取面板数据
     * @returns {AxiosPromise}
     * @param params
     */
    loadIndexData: () => {
        return request({
            url: `/api/physical/detail/select-index-data`,
            method: 'get'
        })
    },
    loadHealthYear: () => {
        return request({
            url: `/api/physical/detail/select-health-user`,
            method: 'get'
        })
    },
    selectIsNotNullPage:(data)=>{
        return request({
            url: `/api/physical/detail/select-isnotnull-data`,
            method: 'get',
            params:data
        })
    },
    selectReviewPage:(data)=>{
        return request({
            url: `/api/physical/detail/select-review-data`,
            method: 'get',
            params:data
        })
    },
    getIndexReportHealth: () => {
        return request({
            url: `/api/physical/detail/report-detail-data`,
            method: 'get'
        })
    },
    getReportTypeHealth: () => {
        return request({
            url: `/api/physical/detail/report-type-detail`,
            method: 'get'
        })
    },

    selectExaminationList:(data)=>{
        return request({
            url: `/api/physical/detail/select-examination-data`,
            method: 'get',
            params:data
        })
    },
})
