import Vue from 'vue'

import AppMain from './AppMain'
import AppBar from './Appbar'
import AppTabs from './AppTabs'
import AppUpload from './AppUpload'
import AppDownload from './AppDownload'
import AppImport from './AppImport'
import AppCaptcha from './AppCaptcha'
import AppIcon from './AppIcon'
import AppTableColumnAction from './AppTableColumnAction'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import AppDocumentPreview from './AppDocumentPreview'

Vue.component('AppMain', AppMain)
Vue.component('AppBar', AppBar)
Vue.component('AppTabs', AppTabs)
Vue.component('AppUpload', AppUpload)
Vue.component('AppDownload', AppDownload)
Vue.component('AppDocumentPreview', AppDocumentPreview)
Vue.component('AppImport', AppImport)
Vue.component('AppCaptcha', AppCaptcha)
Vue.component('AppIcon', AppIcon)
Vue.component('AppTableColumnAction', AppTableColumnAction)
Vue.component('Navbar', Navbar)
Vue.component('Sidebar', Sidebar)

export {
    AppMain,
    AppBar,
    AppTabs,
    AppUpload,
    AppDownload,
    AppImport,
    AppCaptcha,
    AppIcon,
    AppTableColumnAction,
    AppDocumentPreview,
    Navbar,
    Sidebar
}

