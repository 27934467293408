/**
 * @file formatter
 * @author itsnows <xue.com.fei@gmail.com>
 * @since 2021/12/2 下午6:18
 */
import sundial from "./sundial";

/**
 * 日期格式化
 * @param date
 * @param format
 * @returns {*|string}
 */
export function dateFormat(date, format) {
    return !date ? date : sundial.format(date, format)
}

/**
 * 数字格式化
 * @param number
 * @param fractionDigits
 * @returns {string|*}
 */
export function numberFormat(number, fractionDigits = 2) {
    const value = Number(number);
    return isNaN(value) ? number : value.toFixed(fractionDigits)
}
