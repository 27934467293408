import request from '@/utils/request'
import { api } from "@/api";

export default api('sys/read', {
    /**
     * 查询该数据是否已读
     * @param params
     * @returns {*}
     */
    selectReadInfo(params) {
        return request({
            url: '/api/sys/read/select-read-info',
            method: 'get',
            params,
        })
    },
})