import { api } from "@/api";
import request from "@/utils/request";
export default api('oc/occupation', {

    loadList : (params) => {
        return request({
            url: `/api/oc/occupation/load-list`,
            method: 'get',
            params
        })
    },
    /**
     *统计情况
     */
    getReportList(params) {
        return request({
            url: '/api/oc/occupation/select-health-report',
            method: 'post',
            data: JSON.stringify(params)
        })
    },

});
