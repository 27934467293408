import { api } from "@/api";
import request from "@/utils/request";
import {isArray} from "@/utils";

export default api('lab/house-storage', {
    loadOrgTreePageList: (params = {
        index: 0,
        limit: 10
    }) => {
        let like
        if (params.like && isArray(params.like)) {
            like = params.like.map(v => `like=${v}`).join('&')
            delete params.like
        }
        return request({
            url: `/api/lab/house-storage/select-page-tree${like ? '?' + like : ''}`,
            method: 'get',
            params,
            decrypted: {
                paramNames: ['data']
            }
        })
    },
    createNextEntity(superCode) {
        return request({
            url: '/api/lab/house-storage/create-next/' + superCode,
            method: 'get',
        })
    },
    loadSuperiorList: (houseId) => {
        return request({
            url: '/api/lab/house-storage/select-superior/' + houseId,
            method: 'get',
        })
    },
})