import { api } from "@/api";
import { isArray } from "@/utils";
import request from "../../utils/request";

export default api('lab/details-list', {
    /**
     * 读取物资配件关联信息
     * @returns {AxiosPromise}
     */
    loadTreeList: (params = {
        index: 0,
        limit: 10
    }) => {
        let like
        if (params.like && isArray(params.like)) {
            like = params.like.map(v => `like=${v}`).join('&')
            delete  params.like
        }
        return request({
            url: `/api/lab/details-list/load-tree-list${like ? '?' + like : ''}`,
            method: 'get',
            params
        })
    },
})