import { api } from "@/api";
import request from "../../utils/request";

export default api('lab/storage', {
    /**
     * 根据orgcode查看入库
     * @returns {AxiosPromise}
     */
    labWarehouseByOrgCode: (data) => {
        return request({
            url: `/api/lab/storage/ware-house-by-code`,
            method: 'get',
            params:data
        })
    },
    /**
     *统计物资出入库情况
     */
    getReportWareFlowList(params) {
        return request({
            url: '/api/lab/storage/select-flow-report',
            method: 'post',
            data: JSON.stringify(params)
        })
    },
    submit(data) {
        return request({
            url: `/api/lab/storage/submit`,
            method: 'post',
            data: JSON.stringify(data)
        })
    },
})
