import cookies from 'js-cookie'
// mutation
export const TOGGLE_SIDEBAR = 'toggleSidebar'
export const CLOSE_SIDEBAR = 'closeSidebar'
export const TOGGLE_DEVICE = 'toggleDevice'
export const SIZE = 'size'

// actionItems
export const ACTION_TOGGLE_SIDEBAR = 'toggleSideBar'
export const ACTION_CLOSE_SIDEBAR = 'closeSideBar'
export const ACTION_TOGGLE_DEVICE = 'toggleDevice'
export const ACTION_SIZE = 'setSize'

export default {
    state: {
        sidebar: {
            opened: cookies.get('sidebarStatus') ? !!+cookies.get('sidebarStatus') : true,
            withoutAnimation: false
        },
        device: 'desktop',
        size: cookies.get('size') || 'medium'
    },
    mutations: {
        [TOGGLE_SIDEBAR]: state => {
            state.sidebar.opened = !state.sidebar.opened
            state.sidebar.withoutAnimation = false
            if (state.sidebar.opened) {
                cookies.set('sidebarStatus', 1)
            } else {
                cookies.set('sidebarStatus', 0)
            }
        },
        [CLOSE_SIDEBAR]: (state, withoutAnimation) => {
            cookies.set('sidebarStatus', 0)
            state.sidebar.opened = false
            state.sidebar.withoutAnimation = withoutAnimation
        },
        [TOGGLE_DEVICE]: (state, device) => {
            state.device = device
        },
        [SIZE]: (state, size) => {
            state.size = size
            cookies.set('size', size)
        }
    },
    actions: {
        [ACTION_TOGGLE_SIDEBAR]({ commit }) {
            commit(TOGGLE_SIDEBAR)
        },
        [ACTION_CLOSE_SIDEBAR]({ commit }, { withoutAnimation }) {
            commit(CLOSE_SIDEBAR, withoutAnimation)
        },
        [ACTION_TOGGLE_DEVICE]({ commit }, device) {
            commit(TOGGLE_DEVICE, device)
        },
        [ACTION_SIZE]({ commit }, size) {
            commit(SIZE, size)
        }
    }
}
