import request from "@/utils/request";

export default {

    /**
     * 查询文件
     * @param otcId
     * @param otcType
     */
    select: (otcId, otcType) => {
        return request({
            url: '/api/file/select',
            method: 'get',
            params: {
                otcId: otcId,
                otcType: otcType
            }
        })
    },
    /**
     * 查询文件
     * @param otcId
     * @param otcType
     */
    update: (otcId, otcType) => {
        return request({
            url: '/api/file/select',
            method: 'get',
            params: {
                otcId: otcId,
                otcType: otcType
            }
        })
    },

    /**
     * 删除文件
     * @param id
     */
    delete: id => {
        return request({
            url: '/api/file/delete',
            method: 'delete',
            params: {
                id: id
            }
        })
    }
}
