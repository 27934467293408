import { api } from "@/api";
import request from "@/utils/request";
import {isArray} from "@/utils";

export default api('sys/org', {
    /**
     * 获取组织机构树列表
     * @param page
     * @returns {AxiosPromise}
     */
    loadTreeList: () => {
        return request({
            url: `/api/sys/org/select-tree`,
            method: 'get'
        })
    },

    /**
     * 获取上级组织机构列表
     * @returns {AxiosPromise}
     */
    loadSuperList: () => {
        return request({
            url: `/api/sys/org/select-super`,
            method: 'get',
        })
    },
    saveBatch(data) {
        return request({
            url: `/api/sys/org/save-batch`,
            method: 'put',
            data: JSON.stringify(data)
        })
    },
    loadOrderList: (params = {
        index: 0,
        limit: 10
    }) => {
        let like
        if (params.like && isArray(params.like)) {
            like = params.like.map(v => `like=${v}`).join('&')
            delete params.like
        }
        return request({
            url: `/api/sys/org/select-order-item${like ? '?' + like : ''}`,
            method: 'get',
            params
        })
    },

});
