import cookie from 'js-cookie'
import { storageMode } from "@/settings";

const storage = {
    getItem: (key, storage = storageMode) => {
        let value;
        switch (storage) {
            case 'cookie':
                value = cookie.get(key)
                break;
            case 'sessionStorage':
                value = sessionStorage.getItem(key)
                break;
            case 'localStorage':
            default:
                value = localStorage.getItem(key)
                break;
        }
        return value;
    },
    setItem: (key, value, storage = storageMode) => {
        switch (storage) {
            case 'cookie':
                return cookie.set(key, value, {
                    expires: 7
                })
            case 'sessionStorage':
                return sessionStorage.setItem(key, value)
            case 'localStorage':
            default:
                return localStorage.setItem(key, value)
        }
    },
    removeItem: (key, storage = storageMode) => {
        switch (storage) {
            case 'cookie':
                return cookie.remove(key)
            case 'sessionStorage':
                return sessionStorage.removeItem(key)
            case 'localStorage':
            default:
                return localStorage.removeItem(key)
        }
    }
}
export default storage