import { api } from "@/api";
import request from "../../utils/request";

export default api('past/medical', {
    loadList : (params) => {
        return request({
            url: `/api/past/medical/load-list`,
            method: 'get',
            params
        })
    },
    /**
     *统计情况
     */
    getReportList(params) {
        return request({
            url: '/api/past/medical/select-health-report',
            method: 'post',
            data: JSON.stringify(params)
        })
    },
})
