import { asyncRoutes, constRoutes, updateRouter } from '@/router'

import { ROUTES } from '@/store/mutation-types'

export default {
    namespaced: true,
    state: {
        routes: []
    },
    mutations: {
        [ROUTES]: (state, routes) => {
            state.routes = constRoutes.concat(routes)
        }
    },
    actions: {
        addRoutes({ commit }, data) {
            return new Promise(resolve => {
                updateRouter(data)
                const routes = asyncRoutes
                commit(ROUTES, routes)
                resolve(routes)
            })
        },
        resetRoutes({ commit }) {
            return new Promise(resolve => {
                commit(ROUTES, [])
                resolve()
            })
        }
    }
}

