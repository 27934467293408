/**
 * 及时客户端消息处理
 * @constructor
 */
export function IMHandler() {

    this._events = new Map();

    /**
     * 订阅
     * @param event
     * @param handler
     * @returns {IMHandler}
     */
    this.on = (event, handler) => {
        if (typeof event !== 'string')
            throw new Error('Error arguments: event');
        if (typeof handler !== 'function')
            throw new Error('Error arguments: handler');
        if (!this._events.has(event))
            this._events.set(event, []);
        const handlers = this._events.get(event);
        if (handlers.findIndex(h => h === handler) === -1)
            handlers.push(handler);
        else console.log(`Event ${event} already exists handler`);
        return this;
    }

    /**
     * 取消订阅
     * @param event
     * @param handler
     * @returns {IMHandler}
     */
    this.off = (event, handler) => {
        if (!this._events.has(event)) {
            console.log(`Event ${event} unregister`);
            return this;
        }
        const handlers = this._events.get(event);
        const key = handlers.findIndex(h => h === handler);
        if (key !== -1) handlers.splice(key, 1);
        else console.log(`Event ${event} already exists handler`);
        return this;
    }

    /**
     * 发布
     * @param event
     * @param data
     * @returns {IMHandler}
     */
    this.emit = (event, data) => {
        if (!this._events.has(event)) {
            console.log(`Event ${event} unregister`);
            return this;
        }
        const handlers = this._events.get(event);
        handlers.forEach(h => {
            try {
                h.apply(null, [data])
            } catch (e) {
                console.error(e)
            }
        });
        return this;
    }
}