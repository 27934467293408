/**
 * @file mutation-types.js
 * @author itsnows <xue.com.fei@gmail.com>
 * @since 2022/2/17 下午3:57
 */

// auth mutation types.
export const IS_AUTHORIZATION = 'isAuthorization'
export const ROLES_CREDENTIALS = 'rolesCredentials'

// user mutation types.
export const USER_ID = 'userId'
export const USER_NAME = 'userName'
export const USER_AVATAR = 'userAvatar'
export const USER_STATUS = 'userStatus'
export const USER_ACCOUNT = 'userAccount'
export const USER_ROLES = 'userRoles'
export const USER_MENUS = 'userMenus'
export const USER_ORGCODE = 'userOrgCode'
export const USER_ORGLEVEL = 'userLevel'
export const USER_ORGNAME = 'userOrgName'
export const USER_ROLENAME = 'userRoleName'
export const USER_ROLECODE = 'userRoleCode'
export const WAREHOUSE_ID = 'warehouseId'

// permission mutation types
export const ROUTES = 'routes'

// dictionary mutation types
export const OPTIONS = 'options'
