<template>
  <div>
    <el-dialog
        v-if="visible"
        :before-close="handleClose"
        :close-on-click-modal="false"
        :visible.sync="visible"
        top="5vh"
        class="dialog"
        title="导入文档"
        width="75%">
      <div v-loading="loading"
           class="dialog-body">
        <el-upload
            ref="file"
            :accept="fileAccept"
            :auto-upload="false"
            :class="{ hide : fileItems.length >= fileLimit }"
            :file-list="fileItems"
            :limit="fileLimit"
            :on-change="handleChange"
            :on-exceed="handleExceed"
            :on-remove="handleRemove"
            action="#"
            class="dialog-upload"
            list-type="picture-card">
          <i class="el-icon-plus"></i>
          <div slot="file" slot-scope="{ file }">
            <el-image
                :src="file.url"
                alt=""
                class="el-upload-list__item-thumbnail"
                style="width: 72px; height: 72px">
            </el-image>
            <a class="el-upload-list__item-name">
              {{ file.name }}
            </a>
            <span class="el-upload-list__item-actions">
         <span
             class="el-upload-list__item-delete"
             @click="handleRemove(file)"
         >
                 <i class="el-icon-delete"></i>
               </span>
      </span>
          </div>
          <div slot="tip" class="el-upload__tip">只能导入excel文件，且不超过10M；提示：双击表格可进行编辑</div>
        </el-upload>
        <el-table
            v-if="rowItems.length > 0"
            :data="rowItems"
            :summary-method="handleSummary"
            :show-summary="summary"
            border
            highlight-current-row
            stripe
            style="width: 100%; margin-top: 24px"
            @cell-mouse-leave="handleMouse"
            @cell-dblclick="handleEditor">
          <el-table-column
              align="center"
              label="序号"
              type="index"
              width="55">
          </el-table-column>
          <el-table-column
              v-for="item in columnItems"
              :key="item.label"
              :label="item.label"
              :prop="item.prop"
              :width="item.width"
              align="center"
              sortable>
            <template slot-scope="scope">
            <span v-if="item.disabled || (scope.row.props[scope.column.property] && scope.row.props[scope.column.property]['disabled'])"
                  :style="item.disabled ? 'color: #c0c4cc; cursor: not-allowed;' : ''">
              {{ scope.row[item.prop] }}</span>
              <el-input
                  v-else
                  v-model="scope.row[item.prop]"
                  autosize
                  clearable
                  type="textarea"
                  @blur="handleBlur(scope.row, scope.column)"
                  @focus="handleFocus(scope.row, scope.column)"></el-input>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              fixed="right"
              label="操作"
              width="100">
            <template slot-scope="scope">
              <el-button size="small" type="text"
                         @click="handleAction(scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
       <el-button style="float: left"
                  @click="handleDownload">下载模版</el-button>
       <el-button @click="handleClose">取 消</el-button>
       <el-button :disabled="rowItems.length === 0"
                  type="primary"
                  @click="handleOk">确 认</el-button>
    </span>
    </el-dialog>
    <app-download v-if="downloading"
                  :items="downloadItems"
                  :visible="downloading"
                  title="导入模版"
                  v-on:close="handleClose">
    </app-download>
  </div>
</template>

<script>
import { Message } from "element-ui";
import XLSX from "xlsx";
import { numberFormat } from "@/utils/formatter";

export default {
  name: "AppImport",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default() {
        return {
          templates: [],
          downloads: []
        }
      }
    },
    fileSize: {
      type: Number,
      default() {
        //10M
        return 10
      }
    },
    fileAccept: {
      type: String,
      default() {
        return '.xls,.xlsx'
      }
    }
  },
  data() {
    return {
      fileLimit: 1,
      fileItems: [],
      columnItems: [],
      columnWidths: {},
      rowItems: [],
      loading: false,
      downloading: false,
      downloadItems: [],
      template: null
    }
  },
  computed: {
    summary() {
      const {summary = true} = this.template
      return summary
    },
    summaryOptions() {
      const {columns = []} = this.template
      return columns.reduce((pre, cur) => {
        pre[cur.prop] = cur.summary === undefined
        || cur.summary === null ? true : Boolean(cur.summary)
        return pre
      }, {})
    }
  },
  methods: {
    handleRemove(file, fileList) {
      this.fileItems.splice(this.fileItems.findIndex(v => v.uid === file.uid), 1)
      this.columnItems = []
      this.rowItems = []
    },
    handleExceed(file, fileList) {
      Message({
        message: '文件超出限制！',
        type: 'error',
        duration: 3 * 1000
      })
    },
    handleChange(file, fileList) {
      const type = file.name.substring(file.name.lastIndexOf('.'))
      if (!this.fileAccept.includes(type)) {
        Message({
          message: `文件格式只支持${this.fileAccept.split(',').join(' ')}！`,
          type: 'error',
          duration: 3 * 1000
        })
        this.handleRemove(file, fileList)
        return
      }
      const size = file.size / 1024 / 1024
      if (size > this.fileSize) {
        Message({
          message: `文件大小不能超过${this.fileSize}M！`,
          type: 'error',
          duration: 3 * 1000
        })
        this.handleRemove(file, fileList)
        return
      }
      const template = this.options.templates.find(v => {
        const reg = `${v.name.substring(0, v.name.lastIndexOf('.'))}`
        return new RegExp(reg, 'g').test(file.name)
      })
      if (!template) {
        Message({
          message: '无效模版！',
          type: 'error',
          duration: 3 * 1000
        })
        this.handleRemove(file, fileList)
        return
      }
      file.url = this.getThumbnail(type)
      this.fileItems = fileList
      this.template = template
      this.handleDocument(file, template)
    },
    handleDownload(event) {
      this.downloading = true
      this.downloadItems = this.options.downloads || []
    },
    handleClose(event) {
      this.downloading = false
      this.$emit('close', event)
    },
    handleOk(event) {
      this.$emit('ok', {
        target: this.$options.name,
        type: this.template.name,
        data: this.rowItems.map(v => {
          delete v.options
          delete v.props
          delete v.id
          return v
        })
      })
    },
    handleDocument(file, template) {
      let that = this
      let reader = new FileReader();
      reader.readAsBinaryString(file.raw);
      reader.onload = function () {
        try {
          let s = this.result;
          let wb = XLSX.read(s, {
            type: "binary"
          });
          let data = XLSX.utils.sheet_to_json(
              wb.Sheets[wb.SheetNames[0]],
              {
                defval: '',
                raw: true
              });
          if (data.length === 0) {
            Message({
              message: '无效数据！',
              type: 'error',
              duration: 3 * 1000
            })
          } else {
            let uuid = function () {
              let url = URL.createObjectURL(new Blob());
              let uuid = url.toString();
              URL.revokeObjectURL(url);
              return uuid.substring(0, uuid.lastIndexOf("/") + 1);
            }
            const tableWidth = template.columns.reduce((pre, cur) => {
              const value = Number(cur.width);
              if (!isNaN(value)) {
                return pre + value;
              } else {
                return pre;
              }
            }, 0)
            that.$nextTick(() => {
              const trimBlank = s => s.replace(/[\s\t\r\n]*/g, "")
              const cw = that.$refs.file.$el.clientWidth - 155;
              const columns = [];
              const rows = data.map((value, index) => {
                const row = {id: uuid(), props: {}}
                Object.keys(value).forEach(label => {
                  const column = template.columns.find(v => {
                    return trimBlank(v.label) === trimBlank(label)
                  })
                  const prop = column.prop || label
                  row[prop] = value[label]
                  row.props[prop] = {disabled: true, focused: false}
                  if (index === 0) {
                    let disabled = column.disabled || false
                    let width = Number(column.width)
                    width = !isNaN(width) ? width / Math.max(tableWidth, 1) * cw : undefined
                    columns.push({prop, label, width, disabled})
                  }
                })
                return row
              })
              that.columnItems = columns
              that.rowItems = rows
            })
          }
          that.loading = false
        } catch (e) {
          that.loading = false
        }
      }
      that.loading = true
    },
    handleSummary(event) {
      const {columns, data} = event, sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = `合计`;
          return;
        }
        if (this.summaryOptions[column.property]) {
          const values = data.map(item => Number(item[column.property] || undefined));
          if (values.reduce((pre, cur) => {
            return pre && !isNaN(Number(cur));
          }, true)) {
            sums[index] = values.reduce((pre, cur) => {
              const value = Number(cur);
              if (!isNaN(value)) {
                return pre + value;
              } else {
                return pre;
              }
            }, 0);
            return;
          }
        }
        sums[index] = '--';
      });
      return sums.map(v => numberFormat(v));
    },
    handleAction(event) {
      this.rowItems.splice(this.rowItems.findIndex(v => v.id === event.id), 1)
    },
    handleEditor(row, column, cell, event) {
      if (!row.props[column.property]) return
      row.props[column.property].disabled = false
    },
    handleFocus(row, column, cell, event) {
      if (!row.props[column.property]) return
      row.props[column.property].focused = true
    },
    handleBlur(row, column) {
      if (!row.props[column.property]) return
      row.props[column.property].disabled = true
      row.props[column.property].focused = false
    },
    handleMouse(row, column) {
      if (!row.props[column.property]) return
      if (row.props[column.property].focused) return
      row.props[column.property].disabled = true
    },
    getThumbnail(type) {
      let url;
      switch (type) {
        case '.doc':
        case '.docx':
          url = require('@/assets/images/ic_file_word_office.png')
          break
        case '.xls':
        case '.xlsx':
          url = require('@/assets/images/ic_file_excel_office.png')
          break
        default:
          url = require('@/assets/images/ic_file_unknown.png')
          break
      }
      return url
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  &-body {
    max-height: 60vh;
  }

  ::v-deep {
    .el-dialog__body {
      width: 100%;
      overflow-y: scroll;
    }
  }

  &-upload {
    width: 100%;

    ::v-deep {
      .el-upload-list__item {
        width: 100%;
        padding: 14px;
        overflow: hidden;
        transition: none !important;

        &-name {
          display: inline;
          position: absolute;
          margin-left: 24px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    ::v-deep {
      .el-upload {
        width: 100%;

        .el-upload-list--picture-card {
          width: 100%;
        }
      }
    }
  }
}
</style>
