import { api } from "@/api";
import request from "@/utils/request";

export default api('psychological', {

    /**
     * 获取心里评估数据
     * @param page
     * @returns {AxiosPromise}
     */
    loadPsychologicalList: (data) => {
        return request({
            url: `/api/psychological/select-psychological`,
            method: 'get',
            params:data
        })
    },
})
