<template>
  <el-dialog
      :before-close="handleClose"
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="visible"
      width="50%">
    <el-row v-for="(item, index) in items"
            :key="index"
            :style="{'padding-top': `${index === 0 ? 0 : 8}px`,
                     'padding-bottom': `${index < items.length - 1 ? 0 : 8}px`}"
            class="item">
      <span class="item-label">{{ item.label }}</span>
      <el-link type="primary" @click="handleDownload(item)">点击下载</el-link>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose">取 消</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "AppDownload",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '下载列表'
    },
    items: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    handleClose(event) {
      this.$emit('close', event)
    },
    handleDownload(event) {
      if (event.url) {
        let link = document.createElement('a');
        link.href = event.url;
        link.download = event.name || event.url.substring(event.url.lastIndexOf('/') + 1);
        link.target = '_blank';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        link = null;
        this.handleClose()
      } else {
        this.handleClose(event)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  padding-top: 8px;
  padding-bottom: 8px;

  &-label {
    color: #222;
    font-size: 14px;
  }
}
</style>
