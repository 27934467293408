import { api } from "@/api";
import request from "../../utils/request";

export default api('sys/org-address', {
    loadPlanList(Id){
        return request({
            url: `/api/sys/org-address/load-plan-list/${Id}`,
            method: 'get',
        })
    },
    loadOrgCodeList(orgCode){
        return request({
            url: `/api/sys/org-address/load-orgCode-list/${orgCode}`,
            method: 'get',
        })
    },

})