/**
 * 打开文件
 * @param url
 * @param filename
 */
export function openFile(url, filename) {
    filename = filename || getFileName(url)
    if (isOffice(url)) url = `https://view.officeapps.live.com/op/view.aspx?src=${url}`
    let a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.target = '_blank';
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    a = null;
}

/**
 * 获取文件名称
 *
 * @param url
 * @return
 */
export function getFileName(url) {
    let s = url;
    let index = -1;
    if (!s || (index = s.lastIndexOf('/')) === -1) return s
    s = s.substring(index + 1)
    if ((index = s.lastIndexOf('?')) !== -1)
        s = s.substring(0, index)
    return s;
}

/**
 * 获取文件后缀名
 * @param url
 */
export function getFileSuffix(url) {
    let s = getFileName(url);
    let index = -1;
    if ((index = s.indexOf('.')) === -1) return s
    s = s.substring(index)
    return s
}

/**
 * 获取文件类型
 * @param url
 */
export function getFileType(url) {
    let s = getFileName(url);
    let index = -1;
    if ((index = s.lastIndexOf('.')) === -1) return s
    s = s.substring(index + 1)
    return s
}

/**
 * 文件是否是视频
 * @param url
 * @returns {boolean}
 */
export function isVideo(url) {
    let fileType = getFileType(url);
    switch (fileType) {
        case 'mp4':
        case 'm3u8':
        case 'flv':
            return true
        default:
            return false
    }
}

/**
 * 文件是否是图片
 * @param url
 * @returns {boolean}
 */
export function isImage(url) {
    let fileType = getFileType(url);
    switch (fileType) {
        case 'ico':
        case 'png':
        case 'jpg':
        case 'jpeg':
            return true
        default:
            return false
    }
}

/**
 * 文件是否是文档
 * @param url
 * @returns {boolean}
 */
export function isOffice(url) {
    let fileType = getFileType(url);
    switch (fileType) {
        case 'doc':
        case 'docx':
        case 'ppt':
        case 'pptx':
        case 'xls':
        case 'xlsx':
            return true
        default:
            return false
    }
}
