import Vue from 'vue'
import Element from 'element-ui';
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import moment from "moment"

import { UTable, UTableColumn } from 'umy-ui';

Vue.component(UTable.name, UTable);
Vue.component(UTableColumn.name, UTableColumn);
// App.
import storage from "@/utils/storage";
import im from './utils/im'
import './assets/fonts/icon-base/iconfont.css'
import './assets/fonts/icon-abc/iconfont.css'
import './styles/index.scss'
import './filters'
import './icons'
import './utils/permission'

import './assets/fonts/icon-abc/iconfont'

import './styles/element-variables.scss'
import 'normalize.css/normalize.css'
Vue.prototype.$moment = moment;
//定义一个全局过滤器实现日期格式化
Vue.filter('datefmt',function (input,fmtstring) {//当input为时间戳时，需转为Number类型
    // 使用momentjs这个日期格式化类库实现日期的格式化功能
    return moment(input).format(fmtstring);
});
Vue.use(Element, { size: 'medium', zIndex: 2002 })
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import virtualScroller from "vue-virtual-scroller";

Vue.use(virtualScroller);


Vue.config.productionTip = false
Vue.config.errorHandler = (error, vm) => {
    console.error(vm);
    console.error(error);
}
Vue.prototype.$http = axios
Vue.prototype.$storage = storage
Vue.prototype.$im = im
new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})

