import de from "element-ui/src/locale/lang/de";

const CryptoJS = require('crypto-js');

const key = CryptoJS.enc.Utf8.parse("27fd3280103b01eba972d0737d2a3d91");

function iv() {
    let tv = formatTime("yyyyMMdd") + "********";
    let iv = CryptoJS.enc.Utf8.parse(tv);
    return iv;
}

/**
 * 解密
 * @param text
 * @returns {string}
 */
export function decrypt(text) {
    text = text.replace(/-g/, '+').replace(/_/, '/');
    let keyH256 = CryptoJS.SHA256(key);
    let decrypt = CryptoJS.AES.decrypt(text, keyH256, {
        iv: iv(),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    let decrypted = decrypt.toString(CryptoJS.enc.Utf8);
    return decrypted.toString();
}

/**
 * 加密
 * @param text
 * @returns {string}
 */
export function encrypt(text) {
    text = CryptoJS.enc.Utf8.parse(text);
    // mode: CBC,CFB,CTR,CTRGladman,OFB,ECB
    // padding: Pkcs7,Ansix923,Iso10126,ZeroPadding,NoPadding
    // iv: 向量，ECB，不需要iv
    let keyH256 = CryptoJS.SHA256(key);
    let encrypted = CryptoJS.AES.encrypt(text, keyH256, {
        iv: iv(),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    let encryptedBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    let encryptedHex = string2hex(encryptedBase64);
    return encryptedHex;
}

function string2hex(str) {
    let val = "";
    for (let i = 0; i < str.length; i++) {
        if (val === "") val = str.charCodeAt(i).toString(16);
        else val += str.charCodeAt(i).toString(16);
    }
    return val.toUpperCase();
}

function formatTime(fmt) {
    let d = new Date();
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (d.getFullYear() + '').substr(4 - RegExp.$1.length));
    let o = {
        'M+': d.getMonth() + 1,
        'd+': d.getDate(),
        'h+': d.getHours(),
        'm+': d.getMinutes(),
        's+': d.getSeconds()
    };
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            let s = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? s : ('00' + s).substr(s.length));
        }
    }
    return fmt;
}
