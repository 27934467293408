import { api } from "@/api";
import request from "@/utils/request";

export default api('sys/msg', {

    /**
     * 加载列表
     * @param page
     * @returns {AxiosPromise}
     */
    loadList: (page = {
        index: 0,
        limit: 10
    }) => {
        return request({
            url: `/api/sys/msg/list`,
            method: 'get',
            params: page
        })
    },

    /**
     * 阅读消息
     * @param id
     * @returns {AxiosPromise}
     */
    readInfo: id => {
        return request({
            url: `/api/sys/msg/read/${id}`,
            method: 'put',
        })
    }
})
