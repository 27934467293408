import { api } from "@/api";
import request from "../../utils/request";
import {isArray} from "@/utils";

export default api('lab/ware-material', {
    /**
     *统计物资出入库情况
     */
    selectReportStock(params) {
        return request({
            url: '/api/lab/ware-material/select-stock-report',
            method: 'post',
            data: JSON.stringify(params)
        })
    },
    /**
     * 读取物资配件关联信息
     * @returns {AxiosPromise}
     */
    loadTreeList: (params = {
        index: 0,
        limit: 10
    }) => {
        let like
        if (params.like && isArray(params.like)) {
            like = params.like.map(v => `like=${v}`).join('&')
            delete  params.like
        }
        return request({
            url: `/api/lab/ware-material/load-tree-list${like ? '?' + like : ''}`,
            method: 'get',
            params
        })
    },
    /**
     * 查看物品库存
     * @returns {AxiosPromise}
     */
    findByMatNoList: (params = {
        index: 0,
        limit: 10
    }) => {
        let like
        if (params.like && isArray(params.like)) {
            like = params.like.map(v => `like=${v}`).join('&')
            delete  params.like
        }
        return request({
            url: `/api/lab/ware-material/find-mat-no-list${like ? '?' + like : ''}`,
            method: 'get',
            params
        })
    },

    /**
     * 根据物品编号获取仓库库存
     * @returns {AxiosPromise}
     */
    findMaterialByWMNo: (data) => {
        return request({
            url: `/api/lab/ware-material/find-material-by-wmno`,
            method: 'get',
            params:data
        })
    },
    /**
     * 根据机构编码获取物品
     * @returns {AxiosPromise}
     */
    findMaterialByCode: (data) => {
        return request({
            url: `/api/lab/ware-material/find-material-by-code`,
            method: 'get',
            params:data
        })
    },
    /**
     * 根据机构编码获取物品
     * @returns {AxiosPromise}
     */
    findMaterialByLocalCode: (data) => {
        return request({
            url: `/api/lab/ware-material/find-material-by-local-code`,
            method: 'get',
            params:data
        })
    },
    /**
     * 获取当前岗位物资
     * @returns {AxiosPromise}
     */
    loadListPersonItem(params)
    {
        return request({
            url: `/api/lab/ware-material/select-person-page`,
            method: 'get',
            params
        })
    },
    /**
     * 根据二维码识别记录查询对应物资信息
     * @returns {AxiosPromise}
     */
    loadQrInfo:(id) =>{
        return request({
            url: `/api/lab/ware-material/qr-info/${id}`,
            method: 'get'
        })
    },
})
