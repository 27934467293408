/**
 * @file request
 * @author itsnows <xue.com.fei@gmail.com>
 * @since 2021/1/10 上午10:17
 */
import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import router from '@/router';
import { isString, token } from '@/utils'
import { Message, MessageBox } from 'element-ui'

const request = axios.create({
    baseURL: '',
    withCredentials: false,
    timeout: 50000
})

/**
 *  Request interceptor.
 */
request.interceptors.request.use(
    config => {
        config.transformRequest = data => {
            return data
        }
        if (isString(config.data)) {
            config.headers['Content-Type'] = "application/json;charset=utf-8";
        } else if (config.data) {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
            config.data = qs.stringify(config.data)
        }
        if (store.getters.isAuthorization) {
            config.headers[token.name] = token.get()
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

/**
 * Response interceptor.
 */
request.interceptors.response.use(
    response => {
        const res = response.data || {
            success: false,
            code: 99999,
            message: '未知错误'
        }
        // 如果是文件流响应，直接返回 response
        if (response.config.responseType === 'blob') {
            return response.data;
        }
        if (!res.success) {
            if (res.code === 40003 || res.code === 41003) {
                MessageBox.confirm('请重新登录，' + res.message, '登录过期', {
                    confirmButtonText: '重新登录',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    store.dispatch('user/logout').then(() => {
                        router.push(`/login`)
                    })
                })
            }else{
                Message({
                    message: res.message,
                    type: 'error',
                    duration: 4 * 1000
                })
            }
            return Promise.reject(new Error(res.message))
        }
        return res
    }, error => {
        if (error.response.status === 500) {
            error.message = '服务器异常，请稍后重试！'
        }
        Message({
            message: error.message,
            type: 'error',
            duration: 4 * 1000
        })
        return Promise.reject(error)
    }
)

export default request
