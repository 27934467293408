import { api } from "@/api";
import request from "../../utils/request";

export default api('lab/team-rec', {
    distributeItems(Id){
        return request({
            url: `/api/lab/team-rec/distribute/${Id}`,
            method: 'get',
        })
    },
    localGoods(Id){
        return request({
            url: `/api/lab/team-rec/local-goods/${Id}`,
            method: 'get',
        })
    },
    localGoodsRemove(Id){
        return request({
            url: `/api/lab/team-rec/local-remove/${Id}`,
            method: 'get',
        })
    },
    createPerson() {
        return request({
            url: `/api/lab/team-rec/createPerson`,
            method: 'get'
        })
    }
})
