import { api } from "@/api";
import request from "../../utils/request";

export default api('lab/sum-plan-list', {
    getChildrenList(params) {
        return request({
            url: '/api/lab/sum-plan-list/select-children-list',
            method: 'get',
            params,
        })
    }
})
