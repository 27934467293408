import { api } from "@/api";
import request from '@/utils/request'

export default api('lab/resignation', {
    submit(data) {
        return request({
            url: `/api/lab/resignation/submit`,
            method: 'post',
            data: JSON.stringify(data)
        })
    },
})