import { api } from "@/api";
import request from "@/utils/request";

export default api('sys/dictionary', {

    /**
     * 获取字典树列表
     * @returns {AxiosPromise}
     * @param params
     */
    loadTreeList: (params = {
        index: 0,
        limit: 10
    }) => {
        return request({
            url: `/api/sys/dictionary/select-tree`,
            method: 'get',
            params
        })
    },

});
