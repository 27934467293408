/**
 * @file sundial
 * @author itsnows <xue.com.fei@gmail.com>
 * @since 2021/1/10 上午10:17
 */
import moment from 'moment'
import util from 'util'

export const DATETIME_MS = 'YYYY-MM-DD HH:mm:ss.SSS'
export const DATETIME_SECONDS = 'YYYY-MM-DD HH:mm:ss'
export const DATETIME = 'YYYY-MM-DD HH:mm'
export const DATE = 'YYYY-MM-DD'
export const MONTH = 'YYYY-MM'
export const YEAR = 'YYYY'
export const TIME_MS = 'HH:mm:ss.SSS'
export const TIME_SECONDS = 'HH:mm:ss'
export const TIME = 'HH:mm'
export const SECOND_MS = 1000
export const MINUTE_MS = 60 * SECOND_MS
export const HOUR_MS = 60 * MINUTE_MS
export const DAY_MS = 24 * HOUR_MS
export const MONTH_MS = 31 * DAY_MS
export const YEAR_MS = 12 * MONTH_MS
export const WEEK = [
    ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
    ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']]
export default {

    /**
     * 解析时间
     * @param source
     * @param format
     * @returns {Date}
     */
    parse(source, format = DATETIME_SECONDS) {
        return moment(source, format).utcOffset(480).toDate()
    },
    /**
     * 格式化时间
     * @param source
     * @param format
     * @returns {string}
     */
    format(source, format = DATETIME_SECONDS) {
        return moment(source).utcOffset(480).format(format)
    },
    /**
     * 格式化时间（YYYY-MM-DD HH:mm:ss.SSS）
     * @param source
     * @returns {string}
     */
    formatDateTimeMS(source) {
        return this.format(source, DATETIME_MS)
    },
    /**
     * 格式化时间（YYYY-MM-DD HH:mm:ss）
     * @param source
     * @returns {string}
     */
    formatDateTimeSeconds(source) {
        return this.format(source, DATETIME_SECONDS)
    },
    /**
     * 格式化时间（YYYY-MM-DD HH:mm）
     * @param source
     * @returns {string}
     */
    formatDateTime(source) {
        return this.format(source, DATETIME)
    },
    /**
     * 格式化时间（YYYY-MM-DD）
     * @param source
     * @returns {string}
     */
    formatDate(source) {
        return this.format(source, DATE)
    },
    /**
     * 格式化时间（YYYY-MM）
     * @param source
     * @returns {string}
     */
    formatMonth(source) {
        return this.format(source, MONTH)
    },
    /**
     * 格式化时间（YYYY）
     * @param source
     * @returns {string}
     */
    formatYear(source) {
        return this.format(source, YEAR)
    },
    /**
     * 格式化时间（HH:mm:ss.SSS）
     * @param source
     * @returns {string}
     */
    formatTimeMS(source) {
        return this.format(source, TIME_MS)
    },
    /**
     * 格式化时间（HH:mm:ss）
     * @param source
     * @returns {string}
     */
    formatTimeSeconds(source) {
        return this.format(source, TIME_SECONDS)
    },
    /**
     * 格式化时间（HH:mm）
     * @param source
     * @returns {string}
     */
    formatTime(source) {
        return this.format(source, TIME)
    },
    /**
     * 格式化时间（周日, 周一, 周二, 周三, 周四, 周五, 周六）
     * @param source
     * @returns {string}
     */
    formatWeek1(source) {
        const weekday = moment(source).weekday()
        return WEEK[0][weekday]
    },
    /**
     * 格式化时间（星期日, 星期一, 星期二, 星期三, 星期四, 星期五, 星期六）
     * @param source
     * @returns {string}
     */
    formatWeek2(source) {
        const weekday = moment(source).weekday()
        return WEEK[1][weekday]
    },
    /**
     * 格式化毫秒（HH:mm:ss）
     * @param source
     * @returns {string}
     */
    formatDuration(source) {
        let millis = parseFloat(source)
        millis = millis / 1000
        const second = millis % 60
        millis = millis / 60
        const minute = millis % 60
        millis = millis / 60
        const hour = millis % 60
        return util.format('%2d:%2d:%2d', hour, minute, second)
    },
    /**
     * 格式化消息时间（几分钟前、几小时前、几天前、几月前、几年前、刚刚）
     * @param source
     * @returns {string}
     */
    formatMessage1(source) {
        const now = moment().millisecond()
        const diff = now - moment(source).millisecond()
        let i = 0
        if (diff > YEAR_MS) {
            i = (diff / YEAR_MS)
            return i + '年前'
        }
        if (diff > MONTH_MS) {
            i = (diff / MONTH_MS)
            return i + '个月前'
        }
        if (diff > DAY_MS) {
            i = (diff / DAY_MS)
            return i + '天前'
        }
        if (diff > HOUR_MS) {
            i = (diff / HOUR_MS)
            return i + '小时前'
        }
        if (diff > MINUTE_MS) {
            i = (diff / MINUTE_MS)
            return i + '分钟前'
        }
        return '刚刚'
    },
    /**
     * 格式化消息时间（YYYY年M月D日、MM月d日、昨天、HH:mm）
     *
     * @param source
     * @return @returns {string}
     */
    formatMessage2(source) {
        let result = ''
        const todayCalendar = moment()
        const sourceCalendar = moment(source)
        const hourPattern = 'HH:mm'
        const monthPattern = 'MM月d日'
        const yearPattern = 'YYYY年M月D日'
        const isSameYear = todayCalendar.year() === sourceCalendar.year()
        if (isSameYear) {
            const todayMonth = todayCalendar.month()
            const sourceMonth = sourceCalendar.month()
            const isSameMonth = todayMonth === sourceMonth
            if (isSameMonth) {
                const diffDay = todayCalendar.date() - sourceCalendar.date()
                switch (diffDay) {
                    case 0:
                        result = this.format(source, hourPattern)
                        break
                    case 1:
                        result = '昨天'
                        break
                    default:
                        result = this.format(source, monthPattern)
                        break
                }
            } else {
                result = this.format(source, monthPattern)
            }
        } else {
            result = this.format(source, yearPattern)
        }
        return result
    },
    /**
     * 格式化消息时间（HH:mm、M月D日 凌晨|早上|中午|下午|晚上 HH:mm、YYYY年M月D日 凌晨|早上|中午|下午|晚上 HH:mm）
     *
     * @param source
     * @return  @returns {string}
     */
    formatMessage3(source) {
        let result = ''
        let amPm = ''
        const todayCalendar = moment()
        const sourceCalendar = moment(source)
        const hour = sourceCalendar.hour()
        if (hour < 6) {
            amPm = '凌晨'
        } else if (hour < 12) {
            amPm = '早上'
        } else if (hour === 12) {
            amPm = '中午'
        } else if (hour < 18) {
            amPm = '下午'
        } else {
            amPm = '晚上'
        }
        const hourPattern = 'HH:mm'
        const monthPattern = 'M月D日 ' + amPm + 'HH:mm'
        const yearPattern = 'YYYY年M月D日 ' + amPm + 'HH:mm'
        const isSameYear = todayCalendar.year() === sourceCalendar.year()
        if (isSameYear) {
            const todayMonth = todayCalendar.month()
            const sourceMonth = sourceCalendar.month()
            const isSameMonth = todayMonth === sourceMonth
            if (isSameMonth) {
                const dayOfMonth = sourceCalendar.week()
                const todayOfMonth = todayCalendar.week()
                const diffDay = todayCalendar.date() - sourceCalendar.date()
                switch (diffDay) {
                    case 0:
                        result = this.format(source, hourPattern)
                        break
                    case 1:
                        result = '昨天 ' + this.format(source, hourPattern)
                        break
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        if (dayOfMonth === todayOfMonth) {
                            const dayOfWeek = sourceCalendar.weekday()
                            if (dayOfWeek !== 1) {
                                result = WEEK[0][sourceCalendar.weekday() - 1] + this.format(hourPattern, source)
                            } else {
                                result = this.format(source, monthPattern)
                            }
                        } else {
                            result = this.format(source, monthPattern)
                        }
                        break
                    default:
                        result = this.format(source, monthPattern)
                        break
                }
            } else {
                result = this.format(source, monthPattern)
            }
        } else {
            result = this.format(source, yearPattern)
        }
        return result
    }

}
