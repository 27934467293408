/**
 * @file setting
 * @author itsnows <xue.com.fei@gmail.com>
 * @since 2021/10/21 下午2:52
 */
const {
    version,
    name
} = require('../package.json')
module.exports = {
    title: '智慧型物资与设备优化管理系统',
    titleEnglish: 'Intelligent Asset & Equipment Optimization System',
    organization: '成都乐志科技发展有限公司',
    phone: '028-86522758',
    email: '522287166@qq.com',
    copyright: 'Copyright ©2018—2022, ALL Rights Reserved 成都乐志科技发展有限公司',
    icp: '蜀ICP备12029833号',
    icpURL: 'https://beian.miit.gov.cn',
    tokenKey: 'Authorization',
    version,
    name,
    showTabs: true,
    showLogo: true,
    showSettings: true,
    baseURL: 'http://localhost:9090/',
    fileURL: process.env.VUE_APP_FILE_URL,
    imURL: process.env.VUE_APP_IM_URL,
    routerMode: 'remote', // 路由模式 remote: 远程, local: 本地
    storageMode: 'sessionStorage' // 存储模式 sessionStorage, localStorage, cookie
}
