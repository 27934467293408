import { api } from "@/api";
import request from "@/utils/request";

export default api('sys/district', {
    /**
     * 获取行政地区树列表
     * @returns {AxiosPromise}
     */
    loadTreeList: () => {
        return request({
            url: `/api/sys/district/select-tree`,
            method: 'get'
        })
    }
});
