import Vue from "vue";
import router from '@/router'
import store from '@/store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { imURL } from "@/settings";
import { token } from "@/utils";

NProgress.configure({ showSpinner: false })

// 白名单
const allows = ['/login', '/register', '/error']

router.beforeEach(async (to, from, next) => {
    NProgress.start();
    if (store.getters.isAuthorization) {
        if (allows[0] === to.path.slice(0, allows[0].length)) {
            next({ path: '/index' })
            NProgress.done();
        } else if (allows[2] === to.path.slice(0, allows[2].length)) {
            next()
        } else {
            if (store.getters.userRoles.length > 0 &&
                store.getters.userMenus.length > 0) {
                next()
            } else {
                try {
                    const routes = await store.dispatch('permission/addRoutes',
                        await store.dispatch('user/loadRole'), { root: true })
                    router.addRoutes(routes)
                    console.log(routes)
                    if (routes.length === 0) next('/error/40003')
                    else next({ ...to, replace: true })
                } catch (error) {
                    NProgress.done()
                }
            }
        }
        Vue.prototype.$im.emit('connect',`${imURL}?${token.name}=${token.get()}`)
    } else {
        if (allows.indexOf(to.path) !== -1) {
            next()
        } else {
            next(`/login?redirect=${to.path}`)
            NProgress.done()
        }
    }
})
router.afterEach(() => {
    NProgress.done()
})
