import { api } from "@/api";
import request from "@/utils/request";

export default api('sys/function', {

    /**
     * 获取角色列表
     * @param page
     * @returns {AxiosPromise}
     */
    loadFunctionList: () => {
        return request({
            url: `/api/sys/function/select-functions`,
            method: 'get'
        })
    },

    /**
     * 获取用户功能列表
     * @param page
     * @returns {AxiosPromise}
     */
    loadUserFunctionList: () => {
        return request({
            url: `/api/sys/function/select-user-functions`,
            method: 'get'
        })
    },

    /**
     * 获取角色功能列表
     * @param page
     * @returns {AxiosPromise}
     */
    loadRoleFunctionList: sysRoleId => {
        return request({
            url: `/api/sys/function/select-role-functions`,
            method: 'get',
            params: {
                sysRoleId: sysRoleId
            }
        })
    },

    /**
     * 保存角色功能列表
     * @param sysRoleId
     * @param data
     * @returns {AxiosPromise}
     */
    saveRoleFunctionList: (sysRoleId, data) => {
        return request({
            url: `/api/sys/function/save-role-functions`,
            method: 'post',
            params: {
                sysRoleId: sysRoleId
            },
            data: JSON.stringify(data)
        })
    }
})
