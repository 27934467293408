<template>
  <div
      :style="{ width : width, height: height }"
      class="app-captcha"
      @click="refresh">
    <span
        v-for="(item, index) in codes"
        :key="index"
        :style="{
        'color': item.color,
        'font-size': item.fontSize,
        'padding': item.padding,
        'transform': item.transform
      }">
      {{ item.code }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'AppCaptcha',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    width: {
      type: String,
      default: 'auto'
    },
    height: {
      type: String,
      default: '40px'
    },
    length: {
      type: Number,
      default: 4
    }
  },
  data() {
    return {
      codes: []
    }
  },
  mounted() {
    this.refresh()
  },
  methods: {
    refresh() {
      this.generate()
    },
    generate: function () {
      const len = this.length
      const codes = []
      const chars = [...Array(26).keys()]
          .map(c => String.fromCharCode(65 + c))
          .concat([...Array(26).keys()]
              .map(c => String.fromCharCode(97 + c)))
          .concat([...Array(10).keys()]
              .map(c => c.toString()))
          .join(``)
      const charsLen = chars.length
      for (let i = 0; i < len; i++) {
        const rgb = [
          Math.round(Math.random() * 220),
          Math.round(Math.random() * 240),
          Math.round(Math.random() * 200)
        ]
        codes.push({
          code: chars.charAt(Math.floor(Math.random() * charsLen)),
          color: `rgb(${rgb})`,
          fontSize: `${10 + +Number([Math.floor(Math.random() * 10)]) + 6}px`,
          padding: `${[Math.floor(Math.random() * 10)]}px`,
          transform: `rotate(${
              Math.floor(Math.random() * 90) - Math.floor(Math.random() * 90)
          }deg)`
        })
      }
      this.codes = codes
      this.$emit('input', codes.map((item) => item.code).join(''))
    }
  }
}
</script>

<style lang="scss" scoped>
.app-captcha {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    display: inline-block;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
  }
}
</style>
