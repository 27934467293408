import { IMHandler } from './IMHandler'

/**
 * 连接中
 * @type {number}
 */
export const CONNECTING = 0;

/**
 * 连接已建立
 * @type {number}
 */
export const CONNECTED = 1;

/**
 * 连接断开中
 *
 * @type {number}
 */
export const DISCONNECTING = 2;

/**
 * 连接已断开
 *
 * @type {number}
 */
export const DISCONNECTED = 3;

/**
 * 即时消息客户端
 * @param options
 * @constructor
 */
export function IMClient(options = {
    reconnectionIdleTimes: []
}) {

    /**
     * 重连操作空闲时间段
     */
    this.reconnectionIdleTimes = options.reconnectionIdleTimes && options.reconnectionIdleTimes.length > 0 ?
        options.reconnectionIdleTimes : [1000, 3 * 1000, 5 * 1000, 10 * 1000, 60 * 1000];

    /**
     * 重连操作空闲时间
     */
    this.reconnectionIdleTime = 0;

    /**
     * 重连计时器
     */
    this.reconnectionTimer = null;

    /**
     * 重连次数
     */
    this.reconnectionCount = 0;

    /**
     * 连接状态
     */
    this.state = DISCONNECTED;

    /**
     * 连接开启
     * @param ws
     * @param ev
     */
    const onopen = (ws, ev) => {
        console.log('onopen: 连接已建立')
        this.state = CONNECTED
        this.reconnectionCount = 0;
        this.handler.emit('connected', { socket: this })
        if (this.reconnectionTimer) clearInterval(this.reconnectionTimer)
    }

    /**
     * 连接关闭
     * @param ws
     * @param ev
     */
    const onclose = (ws, ev) => {
        console.log('onclose: 连接已断开')
        this.state = DISCONNECTED
        this.handler.emit('disconnected', { socket: this })
        if (!ev || ev.code !== -1) this.reconnect()
    }

    /**
     * 连接错误
     * @param ws
     * @param ev
     */
    const onerror = (ws, ev) => {
        console.log('onerror: 连接已断开')
        this.state = DISCONNECTED
        this.handler.emit('error', { socket: this })
        this.reconnect()
    }

    /**
     * 接收消息
     * @param ws
     * @param ev
     */
    const onmessage = (ws, ev) => {
        console.log('onmessage: 接收消息')
        this.handler.emit('read', { socket: this, data: ws.data })
    }

    /**
     * 连接
     * @param url
     */
    this.connect = url => {
        if (this.state === CONNECTED) {
            console.log('connect: 连接已建立')
            return false;
        }
        if (this.state === CONNECTING) {
            console.log(`connect: 连接中（${this.reconnectionCount + 1}）... `)
            return false;
        }
        if (this.ws) {
            this.ws = null
        }
        this.state = CONNECTING;
        this.handler.emit('connecting', { socket: this })
        this.ws = new WebSocket(url);
        this.ws.onopen = onopen;
        this.ws.onclose = onclose;
        this.ws.onerror = onerror;
        this.ws.onmessage = onmessage;
        console.log(`connect: 连接中（${this.reconnectionCount + 1}）... `)
        return true;
    }

    /**
     * 重连
     */
    this.reconnect = () => {
        const reconnectionIdleTime = this.reconnectionIdleTime;
        if (this.reconnectionCount < this.reconnectionIdleTimes.length)
            this.reconnectionIdleTime = this.reconnectionIdleTimes[this.reconnectionCount];
        else this.reconnectionIdleTime = this.reconnectionIdleTimes.slice(-1)[0]
        if (this.reconnectionIdleTime !== reconnectionIdleTime) {
            if (this.reconnectionTimer) clearInterval(this.reconnectionTimer)
            this.reconnectionTimer = setInterval(() => {
                if (this.connect(this.ws.url)) this.reconnectionCount++;
            }, this.reconnectionIdleTime)
        }
    }

    /**
     * 断开连接
     */
    this.disconnect = () => {
        if (this.state === DISCONNECTED) {
            console.log('disconnect: 连接已断开')
            return false;
        }
        if (this.state === DISCONNECTING) {
            console.log('disconnect: 连接断开中...')
            return false;
        }
        this.state = DISCONNECTING;
        this.handler.emit('disconnecting', { socket: this })
        this.ws.close(-1);
        console.log('disconnect: 连接断开中...')
        return true;
    }

    /**
     * 发送消息
     * @param data
     */
    this.send = data => {
        if (this.state === CONNECTED) {
            this.ws.send(data);
        } else {
        }
    }

    /**
     * 及时消息处理
     */
    this.handler = new IMHandler();
    this.handler.on('connect', this.connect);
    this.handler.on('reconnect', this.reconnect)
    this.handler.on('disconnect', this.disconnect)
    this.handler.on('send', this.send)
}
