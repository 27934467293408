import { api } from "@/api";
import request from "@/utils/request";
export default api('oc/lnterr', {

    loadList : (params) => {
        return request({
            url: `/api/oc/lnterr/load-list`,
            method: 'get',
            params
        })
    },

    addLnterrList : (data) => {
        return request({
            url: `/api/oc/lnterr/add-lnterr-list`,
            method: 'put',
            data: JSON.stringify(data)
        })
    }

});
