import { sysUserApi } from '@/api';
import token from '@/utils/token';
import { resetRouter } from "@/router";
import { routerMode } from '@/settings'

import {
    IS_AUTHORIZATION,
    ROLES_CREDENTIALS,
    USER_ACCOUNT,
    USER_AVATAR,
    USER_ID,
    USER_MENUS,
    USER_NAME,
    USER_ORGCODE,
    USER_ROLES,
    USER_STATUS,
    USER_ORGLEVEL,
    USER_ORGNAME,
    USER_ROLENAME,
    USER_ROLECODE,
    WAREHOUSE_ID,
} from '@/store/mutation-types'
import {encrypt} from "@/utils/encrypt-aes";

export default {
    namespaced: true,
    state: {
        userId: sessionStorage.getItem("userId") || {},
        userName: sessionStorage.getItem("userName") || {},
        userAvatar: sessionStorage.getItem("userAvatar") || {},
        userStatus: sessionStorage.getItem("userStatus") || {},
        userAccount: sessionStorage.getItem("userAccount") || {},
        userOrgCode:sessionStorage.getItem("userOrgCode") || {},
        userOrgName:sessionStorage.getItem("userOrgName") || {},
        userLevel:sessionStorage.getItem("userLevel") || {},
        userRoleName:sessionStorage.getItem("userRoleName") || {},
        userRoleCode:sessionStorage.getItem("userRoleCode") || {},
        userRoles: [],
        userMenus: [],
        warehouseId:sessionStorage.getItem("warehouseId") || {}
    },
    // getters: {
    //     userId: state => state.userId,
    //     userName: state => state.userName,
    //     userAvatar: state => state.userAvatar,
    //     userStatus: state => state.userStatus,
    //     userAccount: state => state.userAccount,
    //     userRoles: state => state.userRoles,
    //     userMenus: state => state.userMenus
    // },
    mutations: {
        [USER_ID]: (state, userId) => {
            state.userId = userId
            sessionStorage.setItem("userId", userId)
        },
        [USER_NAME]: (state, userName) => {
            state.userName = userName
            sessionStorage.setItem("userName", userName)
        },
        [USER_AVATAR]: (state, userAvatar) => {
            state.userAvatar = userAvatar
            sessionStorage.setItem("userAvatar", userAvatar)
        },
        [USER_STATUS]: (state, userStatus) => {
            state.userStatus = userStatus
            sessionStorage.setItem("userStatus", userStatus)
        },
        [USER_ACCOUNT]: (state, userAccount) => {
            state.userAccount = userAccount
            sessionStorage.setItem("userAccount", userAccount)
        },
        [USER_ROLES]: (state, userRoles) => state.userRoles = userRoles,
        [USER_MENUS]: (state, userMenus) => state.userMenus = userMenus,
        [USER_ORGCODE]: (state, userOrgCode) => {
            state.userOrgCode = userOrgCode
            sessionStorage.setItem("userOrgCode", userOrgCode)
        },
        [USER_ORGLEVEL]: (state, userLevel) => {
            state.userLevel = userLevel
            sessionStorage.setItem("userLevel", userLevel)
        },
        [USER_ORGNAME]: (state, userOrgName) => {
            state.userOrgName = userOrgName
            sessionStorage.setItem("userOrgName", userOrgName)
        },
        [USER_ROLENAME]: (state, userRoleName) => {
            state.userRoleName = userRoleName
            sessionStorage.setItem("userRoleName", userRoleName)
        },
        [USER_ROLECODE]: (state, userRoleCode) => {
            state.userRoleCode = userRoleCode
            sessionStorage.setItem("userRoleCode", userRoleCode)
        },
        [WAREHOUSE_ID]: (state, warehouseId) => {
            state.warehouseId = warehouseId
            sessionStorage.setItem("warehouseId", warehouseId)
        }
    },
    actions: {
        login({ dispatch, commit }, data) {
            commit(USER_ROLES, [])
            commit(USER_MENUS, [])
            return new Promise((resolve, reject) => {
                data = Object.keys(data).reduce((obj, key) => {
                    obj[key] = encrypt(data[key])
                    return obj
                }, {})
                const { username, password } = data
                sysUserApi.login({
                    username: username.trim(),
                    password: password.trim()
                }).then(async res => {
                    const { data } = res
                    const { roles, credentials } = data
                    token.set(credentials.accessToken);
                    commit(IS_AUTHORIZATION, true, { root: true })
                    commit(ROLES_CREDENTIALS, roles, { root: true })
                    commit(USER_ID, roles.userId)
                    commit(USER_NAME, roles.userName)
                    commit(USER_ORGCODE, roles.orgCode)
                    commit(USER_ORGLEVEL, roles.orgLevel)
                    commit(USER_ORGNAME, roles.orgName)
                    commit(USER_AVATAR, roles.userAvatar)
                    commit(USER_STATUS, roles.userStatus)
                    commit(USER_ACCOUNT, roles.userAccount)
                    commit(USER_ROLECODE, roles.userRoleCode)
                    commit(USER_ROLENAME, roles.userRoleName)
                    commit(WAREHOUSE_ID, roles.warehouseId)
                    resolve()
                }).catch(err => {
                    reject(err)
                })
            })
        },
        logout({ dispatch, commit }) {
            return new Promise((resolve, reject) => {
                sysUserApi.logout().then(async res => {
                    token.remove()
                    await dispatch('resetRole')
                    commit(IS_AUTHORIZATION, false, { root: true })
                    commit(ROLES_CREDENTIALS, [], { root: true })
                    sessionStorage.clear()
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        loadRole({ dispatch, commit }) {
            return new Promise((resolve, reject) => {
                if (routerMode === 'local') {
                    commit(USER_ROLES, [{}])
                    commit(USER_MENUS, [{}])
                    dispatch('dictionary/loadOptions', null, { root: true })
                    resolve([])
                } else {
                    sysUserApi.loadRoleList().then(res => {
                        const { data } = res
                        const { roles, functions } = data
                        commit(USER_ROLES, roles)
                        commit(USER_MENUS, functions)
                        dispatch('dictionary/loadOptions', null, { root: true })
                        resolve(functions)
                    }).catch(err => {
                        reject(err)
                    });
                }
            })
        },
        resetRole({ dispatch, commit }) {
            return new Promise(async resolve => {
                commit(USER_ROLES, [])
                commit(USER_MENUS, [])
                await dispatch('tabs/delAllTabs', null, { root: true })
                await dispatch('permission/resetRoutes', null, { root: true })
                resetRouter()
                resolve()
            })
        },
        loadInfo({ commit }, id) {
            return new Promise((resolve, reject) => {
                sysUserApi.loadInfo(id).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        saveInfo({ commit }, data) {
            return new Promise((resolve, reject) => {
                sysUserApi.saveInfo(data).then(res => {
                    resolve(res)
                }).catch(err => {
                    reject(err)
                })
            })
        }
    }
}
