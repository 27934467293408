<template>
  <div v-if="isUrl"
       :style="{
         'mask': `url(${icon}) no-repeat 50% 50%`,
         '-webkit-mask': `url(${icon}) no-repeat 50% 50%`
       }"
       class="icon icon-url"
       v-on="$listeners"/>
  <i v-else-if="isFont"
     :class="icon"
     class="icon icon-i"
     v-on="$listeners"/>
  <svg v-else
       aria-hidden="true"
       class="icon icon-svg"
       v-on="$listeners">
    <use :href="`#icon-${ icon }`"/>
  </svg>
</template>

<script>

export default {
  name: 'AppIcon',
  props: {
    icon: {
      type: String,
      required: true
    },
    iconfont: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isFont() {
      return (this.icon && this.icon.includes('el-icon')) || this.iconfont
    },
    isUrl() {
      return /^(https?:|mailto:|tel:)/.test(this.icon) ||
          /^(data:image\/\w+;base64,)/.test(this.icon)
    }
  }
}
</script>

<style lang="scss" scoped>
.icon {
  width: 1em;
  height: 1em;

  &-i {
    // vertical-align: -10%;
    vertical-align: -0.15em;
    color: currentColor;
    font-size: 16px;
  }

  &-svg {
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }

  &-url {
    background-color: currentColor;
    mask-size: cover !important;
    display: inline-block;
  }

}
</style>
