const state = {
    visitedTabs: [],
    cachedTabs: []
}

const mutations = {
    ADD_VISITED_TAB: (state, view) => {
        if (state.visitedTabs.some(v => v.path === view.path)) return
        state.visitedTabs.push(
            Object.assign({}, view, {
                title: view.meta.title || 'no-name'
            })
        )
    },
    ADD_CACHED_TAB: (state, view) => {
        if (state.cachedTabs.includes(view.name)) return
        if (!view.meta.noCache) {
            state.cachedTabs.push(view.name)
        }
    },
    DEL_VISITED_TAB: (state, view) => {
        for (const [i, v] of state.visitedTabs.entries()) {
            if (v.path === view.path) {
                state.visitedTabs.splice(i, 1)
                break
            }
        }
    },
    DEL_CACHED_TAB: (state, view) => {
        const index = state.cachedTabs.indexOf(view.name)
        index > -1 && state.cachedTabs.splice(index, 1)
    },
    DEL_OTHERS_VISITED_TAB: (state, view) => {
        state.visitedTabs = state.visitedTabs.filter(v => {
            return v.meta.affix || v.path === view.path
        })
    },
    DEL_OTHERS_CACHED_TABS: (state, view) => {
        const index = state.cachedTabs.indexOf(view.name)
        if (index > -1) {
            state.cachedTabs = state.cachedTabs.slice(index, index + 1)
        } else {
            state.cachedTabs = []
        }
    },
    DEL_RIGHTS_VISITED_TABS: (state, view) => {
        const index = state.visitedTabs.findIndex(v => v.path === view.path);
        if (index > -1) state.visitedTabs = state.visitedTabs.slice(0, index + 1)
    },
    DEL_RIGHTS_CACHED_TABS: (state, view) => {
        const index = state.cachedTabs.indexOf(view.path)
        if (index > -1) state.cachedTabs = state.cachedTabs.slice(0, index + 1)
    },
    DEL_ALL_VISITED_TABS: state => {
        state.visitedTabs = state.visitedTabs.filter(tag => tag.meta.affix)
    },
    DEL_ALL_CACHED_TABS: state => {
        state.cachedTabs = []
    },
    UPDATE_VISITED_TAB: (state, view) => {
        for (let v of state.visitedTabs) {
            if (v.path === view.path) {
                v = Object.assign(v, view)
                break
            }
        }
    }
}

const actions = {
    addTab({ dispatch }, view) {
        dispatch('addVisitedTab', view)
        dispatch('addCachedTab', view)
    },
    addVisitedTab({ commit }, view) {
        commit('ADD_VISITED_TAB', view)
    },
    addCachedTab({ commit }, view) {
        commit('ADD_CACHED_TAB', view)
    },
    delTab({ dispatch, state }, view) {
        return new Promise(resolve => {
            dispatch('delVisitedTab', view)
            dispatch('delCachedTab', view)
            resolve({
                visitedTabs: [...state.visitedTabs],
                cachedTabs: [...state.cachedTabs]
            })
        })
    },
    delVisitedTab({ commit, state }, view) {
        return new Promise(resolve => {
            commit('DEL_VISITED_TAB', view)
            resolve([...state.visitedTabs])
        })
    },
    delCachedTab({ commit, state }, view) {
        return new Promise(resolve => {
            commit('DEL_CACHED_TAB', view)
            resolve([...state.cachedTabs])
        })
    },
    delOthersTabs({ dispatch, state }, view) {
        return new Promise(resolve => {
            dispatch('delOthersVisitedTabs', view)
            dispatch('delOthersCachedTabs', view)
            resolve({
                visitedTabs: [...state.visitedTabs],
                cachedTabs: [...state.cachedTabs]
            })
        })
    },
    delRightsTabs({ dispatch, state }, view) {
        return new Promise(resolve => {
            dispatch('delRightsVisitedTabs', view)
            dispatch('delRightsCachedTabs', view)
            resolve({
                visitedTabs: [...state.visitedTabs],
                cachedTabs: [...state.cachedTabs]
            })
        })
    },
    delOthersVisitedTabs({ commit, state }, view) {
        return new Promise(resolve => {
            commit('DEL_OTHERS_VISITED_TAB', view)
            resolve([...state.visitedTabs])
        })
    },
    delOthersCachedTabs({ commit, state }, view) {
        return new Promise(resolve => {
            commit('DEL_OTHERS_CACHED_TABS', view)
            resolve([...state.cachedTabs])
        })
    },
    delRightsVisitedTabs({ commit, state }, view) {
        return new Promise(resolve => {
            commit('DEL_RIGHTS_VISITED_TABS', view)
            resolve([...state.visitedTabs])
        })
    },
    delRightsCachedTabs({ commit, state }, view) {
        return new Promise(resolve => {
            commit('DEL_RIGHTS_CACHED_TABS', view)
            resolve([...state.cachedTabs])
        })
    },
    delAllTabs({ dispatch, state }, view) {
        return new Promise(resolve => {
            dispatch('delAllVisitedTabs', view)
            dispatch('delAllCachedTabs', view)
            resolve({
                visitedTabs: [...state.visitedTabs],
                cachedTabs: [...state.cachedTabs]
            })
        })
    },
    delAllVisitedTabs({ commit, state }) {
        return new Promise(resolve => {
            commit('DEL_ALL_VISITED_TABS')
            resolve([...state.visitedTabs])

        })
    },
    delAllCachedTabs({ commit, state }) {
        return new Promise(resolve => {
            commit('DEL_ALL_CACHED_TABS')
            resolve([...state.cachedTabs])
        })
    },
    updateVisitedTab({ commit }, view) {
        commit('UPDATE_VISITED_TAB', view)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
