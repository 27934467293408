/**
 * @file strings
 * @author itsnows <xue.com.fei@gmail.com>
 * @since 2021/12/2 下午6:01
 */
const strings = {
    trim(s, c) {
        if (c == null || c === '') {
            let str = s.replace(/^s*/, '');
            let rg = /s/;
            let i = str.length;
            while (rg.test(str.charAt(--i))) ;
            return str.slice(0, i + 1);
        } else {
            let rg = new RegExp("^" + c + "*");
            let str = s.replace(rg, '');
            rg = new RegExp(c);
            let i = str.length;
            while (rg.test(str.charAt(--i))) ;
            return str.slice(0, i + 1);
        }
    },
    trimStart(s, c) {
        if (c == null || c === '') {
            return this.replace(/^s*/, '');
        } else {
            let rg = new RegExp("^" + c + "*");
            return s.replace(rg, '');
        }
    },
    trimEnd(s, c) {
        if (c == null || c === '') {
            let str = s;
            let rg = /s/;
            let i = str.length;
            while (rg.test(str.charAt(--i))) ;
            return str.slice(0, i + 1);
        } else {
            let str = s;
            let rg = new RegExp(c);
            let i = str.length;
            while (rg.test(str.charAt(--i))) ;
            return str.slice(0, i + 1);
        }
    }
}

module.exports = strings
