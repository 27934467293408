import { api } from "@/api";
import request from "../../utils/request";

export default api('lab/purchase', {

    updateById(data) {
        return request({
            url: `/api/lab/purchase/update-by-Id/${data}`,
            method: 'get',
        })
    },

    selectByPlanId(Id){
        return request({
            url: `/api/lab/purchase/select-by-plan-Id/${Id}`,
            method: 'get',
        })
    },
    noticePurchase(Id){
        return request({
            url: `/api/lab/purchase/notice-purchase-Id/${Id}`,
            method: 'get',
        })
    },
    surePurchase(Id){
        return request({
            url: `/api/lab/purchase/sure-purchase-Id/${Id}`,
            method: 'get',
        })
    },
    checkedWare(Id){
        return request({
            url: `/api/lab/purchase/checked-plan/${Id}`,
            method: 'get',
        })
    },
    backFill(Id){
        return request({
            url: `/api/lab/purchase/back-plan-fill/${Id}`,
            method: 'get',
        })
    },
    selectPageByLevel: (data) => {
        return request({
            url: `/api/lab/purchase/select-page-by-level`,
            method: 'get',
            params:data
        })
    },
    selectAddressByPlanId(Id){
        return request({
            url: `/api/lab/purchase/select-address-plan-Id/${Id}`,
            method: 'get',
        })
    },
})
