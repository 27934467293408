import { api } from "@/api";
import request from "../../utils/request";
export default api('hazard/record', {
    /**
     *统计物资使用情况
     */
    getReportList(params) {
        return request({
            url: '/api/hazard/record/select-record-report',
            method: 'post',
            data: JSON.stringify(params)
        })
    },
})
