import { api } from "@/api";
import request from "../../utils/request";

export default api('lab/subordinate', {
    getByLabPurchaseId(orgCode,Id){
        return request({
            url: `/api/lab/subordinate/getByLabPurchaseId/${orgCode}/${Id}`,
            method: 'get',
        })
    },
})
