import { sysDictionaryApi } from '@/api';
import { OPTIONS } from '@/store/mutation-types'

export default {
    namespaced: true,
    state: {
        options: {}
    },
    mutations: {
        [OPTIONS]: (state, options) => state.options = options
    },
    actions: {
        loadOptions({ commit }) {
            sysDictionaryApi.loadTreeList({
                limit: 10000
            }).then(res => {
                const find = (child) => {
                    const dictionary = {
                        label: child.dictionaryLabel,
                        value: child.dictionaryValue
                    }
                    if (child.sysDictionaries) {
                        dictionary.children = []
                        if (child.sysDictionaries.length > 0) {
                            dictionary.children = child.sysDictionaries.map(child => find(child))
                        }
                    }
                    return dictionary
                }
                let dictionaries = res.data.items.reduce((pre, cur) => {
                    pre[cur.dictionaryValue] = find(cur).children || []
                    return pre
                }, {});
                commit(OPTIONS, dictionaries)
            })
        }
    }
}