import { api } from "@/api";
import request from "../../utils/request";

export default api('lab/plan-list', {
    /**
     * 批量采购清单
     * @param data
     */
    saveBatch(data) {
        return request({
            url: `/api/lab/plan-list/save-batch`,
            method: 'put',
            data: JSON.stringify(data)
        })
    },
    findLabProcurePlanById(Id){
        return request({
            url: `/api/lab/plan-list/find-lab-by-Id/${Id}`,
            method: 'get',
        })
    },
    summaryById(Id){
        return request({
            url: `/api/lab/plan-list/summary-by-Id/${Id}`,
            method: 'get',
        })
    },
    summaryOrgById(Id){
        return request({
            url: `/api/lab/plan-list/summary-org-Id/${Id}`,
            method: 'get',
        })
    },
    sendCollecting(Id,orgCode){
        return request({
            url: `/api/lab/plan-list/sendCollecting/${Id}/${orgCode}`,
            method: 'get',
        })
    },

})
