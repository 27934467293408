import { api } from "@/api";
import request from "../../utils/request";
import { isArray } from "@/utils";
export default api('lab/personal-rec', {
    /**
     * 批量采购清单
     * @param data
     */
    saveBatch(data) {
        return request({
            url: `/api/lab/personal-rec/save-batch`,
            method: 'put',
            data: JSON.stringify(data)
        })
    },
    /**
     * 读取物资配件关联信息
     * @returns {AxiosPromise}
     */
    loadTreeList: (params = {
        index: 0,
        limit: 10
    }) => {
        let like
        if (params.like && isArray(params.like)) {
            like = params.like.map(v => `like=${v}`).join('&')
            delete  params.like
        }
        return request({
            url: `/api/lab/personal-rec/load-tree-list${like ? '?' + like : ''}`,
            method: 'get',
            params
        })
    },
    /**
     *统计物资使用情况
     */
    getReportList(params) {
        return request({
            url: '/api/lab/personal-rec/select-report',
            method: 'post',
            data: JSON.stringify(params)
        })
    },
    selectIndexList() {
        return request({
            url: '/api/lab/personal-rec/select-index-list',
            method: 'post'
        })
    },

})
