import AppMain from "@/layout/components/AppMain";
import Layout from "@/layout";

/**
 * @file 管理员
 * @author itsnows <xue.com.fei@gmail.com>
 * @since 2021/12/23 上午10:05
 */
export const adminRoutes = [
    {
        path: '/base',
        redirect: '/base/district',
        name: '基础数据',
        meta: {
            title: '基础数据',
            icon: 'icon-hierarchy-line',
            roles: [
                'admin'
            ]
        },
        children: [
            {
                path: 'district',
                redirect: '/base/district/list',
                name: '地区管理',
                meta: {
                    title: '地区管理',
                    icon: 'icon-government-line',
                    roles: [
                        'admin'
                    ]
                },
                children: [
                    {
                        path: 'list',
                        name: '地区列表',
                        meta: {
                            title: '地区列表',
                            icon: 'icon-government-line',
                            noCache: true,
                            activeMenu: '/base/district',
                            roles: [
                                'admin'
                            ]
                        },
                        hidden: true,
                        component: () => import('@/views/sys/district/list/index')
                    },
                    {
                        path: 'view',
                        name: '地区信息',
                        meta: {
                            title: '地区信息',
                            icon: 'icon-government-line',
                            noCache: true,
                            activeMenu: '/base/district',
                            roles: [
                                'admin'
                            ]
                        },
                        hidden: true,
                        component: () => import('@/views/sys/district/view/index')
                    },
                ],
                component: AppMain
            },
            {
                path: 'org',
                redirect: '/base/org/list',
                name: '机构管理',
                meta: {
                    title: '机构管理',
                    icon: 'icon-a-structuremanagement-line',
                    roles: [
                        'admin'
                    ]
                },
                children: [
                    {
                        path: 'list',
                        name: '机构列表',
                        meta: {
                            title: '机构列表',
                            icon: 'icon-a-structuremanagement-line',
                            noCache: true,
                            activeMenu: '/base/org',
                            roles: [
                                'admin'
                            ]
                        },
                        hidden: true,
                        component: () => import('@/views/sys/org/list/index')
                    },
                    {
                        path: 'view',
                        name: '机构信息',
                        meta: {
                            title: '机构信息',
                            icon: 'icon-a-structuremanagement-line',
                            noCache: true,
                            activeMenu: '/base/org',
                            roles: [
                                'admin'
                            ]
                        },
                        hidden: true,
                        component: () => import('@/views/sys/org/view/index')
                    },
                ],
                component: AppMain
            },
            {
                path: 'dictionary',
                redirect: '/base/dictionary/list',
                name: '字典管理',
                meta: {
                    title: '字典管理',
                    icon: 'icon-dict-line',
                    roles: []
                },
                children: [
                    {
                        path: 'list',
                        name: '字典列表',
                        meta: {
                            title: '字典列表',
                            icon: 'icon-dict-line',
                            noCache: true,
                            activeMenu: '/base/dictionary',
                            roles: [
                                'admin'
                            ]
                        },
                        hidden: true,
                        component: () => import('@/views/sys/dictionary/list/index')
                    }
                ],
                component: AppMain
            },
            {
                path: 'sms',
                redirect: '/base/sms/list',
                name: '短信管理',
                meta: {
                    title: '短信管理',
                    icon: 'icon-a-message5-line',
                    roles: []
                },
                children: [
                    {
                        path: 'list',
                        name: '短信列表',
                        meta: {
                            title: '短信列表',
                            icon: 'icon-a-message5-line',
                            noCache: true,
                            activeMenu: '/base/sms',
                            roles: []
                        },
                        hidden: true,
                        component: () => import('@/views/sys/sms/list/index')
                    }
                ],
                component: AppMain
            },
            {
                path: 'msg',
                redirect: '/base/msg/list',
                name: '通知公告',
                meta: {
                    title: '通知公告',
                    icon: 'icon-a-message3-line',
                    roles: []
                },
                children: [
                    {
                        path: 'list',
                        name: '通知公告列表',
                        meta: {
                            title: '通知公告列表',
                            icon: 'icon-a-message3-line',
                            noCache: true,
                            activeMenu: '/base/msg',
                            roles: []
                        },
                        hidden: true,
                        component: () => import('@/views/sys/msg/list/index')
                    }
                ],
                component: AppMain
            }
        ],
        component: Layout
    },
    {
        path: '/sys',
        redirect: '/sys/user',
        name: '系统管理',
        meta: {
            title: '系统管理',
            icon: 'icon-set-line',
            roles: [
                'admin'
            ]
        },
        children: [
            {
                path: 'user',
                redirect: '/sys/user/list',
                name: '用户管理',
                meta: {
                    title: '用户管理',
                    icon: 'icon-a-userlist-line',
                    roles: [
                        'admin'
                    ]
                },
                children: [
                    {
                        path: 'list',
                        name: '用户列表',
                        meta: {
                            title: '用户列表',
                            icon: 'icon-a-userlist-line',
                            noCache: true,
                            activeMenu: '/sys/user',
                            roles: [
                                'admin',
                                'import',
                                'export'
                            ]
                        },
                        hidden: true,
                        component: () => import('@/views/sys/user/list/index')
                    },
                    {
                        path: 'view',
                        name: '用户信息',
                        meta: {
                            title: '用户信息',
                            icon: 'icon-a-userlist-line',
                            noCache: true,
                            activeMenu: '/sys/user',
                            roles: [
                                'admin'
                            ]
                        },
                        hidden: true,
                        component: () => import('@/views/sys/user/view/index')
                    },
                ],
                component: AppMain
            },
            {
                path: 'function',
                redirect: '/sys/function/list',
                name: '功能管理',
                meta: {
                    title: '功能管理',
                    icon: 'icon-model-line',
                    roles: [
                        'admin'
                    ]
                },
                children: [
                    {
                        path: 'list',
                        name: '功能列表',
                        meta: {
                            title: '功能列表',
                            icon: 'icon-model-line',
                            noCache: true,
                            activeMenu: '/sys/function',
                            roles: [
                                'add',
                                'edit',
                                'delete'
                            ]
                        },
                        hidden: true,
                        component: () => import('@/views/sys/function/list/index')
                    },
                    {
                        path: 'view',
                        name: '功能信息',
                        meta: {
                            title: '功能信息',
                            icon: 'icon-model-line',
                            noCache: true,
                            activeMenu: '/sys/function',
                            roles: [
                                'admin'
                            ]
                        },
                        hidden: true,
                        component: () => import('@/views/sys/function/view/index')
                    }
                ],
                component: AppMain

            },
            {
                path: 'role',
                redirect: '/sys/role/list',
                name: '角色管理',
                meta: {
                    title: '角色管理',
                    icon: 'icon-a-userpermissions-line',
                    roles: [
                        'admin'
                    ]
                },
                children: [
                    {
                        path: 'list',
                        name: '角色列表',
                        meta: {
                            title: '角色列表',
                            icon: 'icon-a-userpermissions-line',
                            noCache: true,
                            activeMenu: '/sys/role',
                            roles: [
                                'add',
                                'edit',
                                'delete'
                            ]
                        },
                        hidden: true,
                        component: () => import('@/views/sys/role/list/index')
                    },
                    {
                        path: 'view',
                        name: '角色授权',
                        meta: {
                            title: '角色授权',
                            icon: 'icon-a-userpermissions-line',
                            noCache: true,
                            activeMenu: '/sys/role',
                            roles: [
                                'admin'
                            ]
                        },
                        hidden: true,
                        component: () => import('@/views/sys/role/view/index')
                    }
                ],
                component: AppMain
            },
            {
                path: 'log-login',
                redirect: '/sys/log-login/list',
                name: '登录日志',
                meta: {
                    title: '登录日志',
                    icon: 'icon-catalog-line',
                    roles: []
                },
                children: [
                    {
                        path: 'list',
                        name: '登录日志列表',
                        meta: {
                            title: '登录日志列表',
                            icon: 'icon-catalog-line',
                            noCache: true,
                            activeMenu: '/sys/log-login',
                            roles: [
                                'delete'
                            ]
                        },
                        hidden: true,
                        component: () => import('@/views/sys/log-login/list/index')
                    }
                ],
                component: AppMain
            },
            {
                path: 'log-opt',
                redirect: '/sys/log-opt/list',
                name: '操作日志',
                meta: {
                    title: '操作日志',
                    icon: 'icon-shield-line',
                    roles: []
                },
                children: [
                    {
                        path: 'list',
                        name: '操作日志列表',
                        meta: {
                            title: '操作日志列表',
                            icon: 'icon-shield-line',
                            noCache: true,
                            activeMenu: '/sys/log-opt',
                            roles: [
                                'delete'
                            ]
                        },
                        hidden: true,
                        component: () => import('@/views/sys/log-opt/list/index')
                    },
                    {
                        path: 'view',
                        name: '操作日志信息',
                        meta: {
                            title: '操作日志信息',
                            icon: 'icon-shield-line',
                            noCache: true,
                            activeMenu: '/sys/log-opt',
                            roles: []
                        },
                        hidden: true,
                        component: () => import('@/views/sys/log-opt/view/index')
                    }
                ],
                component: AppMain
            }
        ],
        component: Layout
    }
]
