import { api } from "@/api";
import request from "../../utils/request";
import {isArray} from "@/utils";

export default api('lab/warehouse', {
    /**
     * 获取组织机构树列表
     * @param page
     * @returns {AxiosPromise}
     */
    loadTreeList: (params = {
        index: 0,
        limit: 10
    }) => {
        let like
        if (params.like && isArray(params.like)) {
            like = params.like.map(v => `like=${v}`).join('&')
            delete params.like
        }
        return request({
            url: `/api/lab/warehouse/select-tree${like ? '?' + like : ''}`,
            method: 'get',
            params
        })
    },
    /**
     * 获取组织机构树列表
     * @param page
     * @returns {AxiosPromise}
     */
    loadSuperTreeList: () => {
        return request({
            url: `/api/lab/warehouse/select-super-tree`,
            method: 'get'
        })
    },
    /**
     * 获取全部组织机构树列表
     * @param page
     * @returns {AxiosPromise}
     */
    loadAllTreeList: () => {
        return request({
            url: `/api/lab/warehouse/select-all-tree`,
            method: 'get'
        })
    }
})
