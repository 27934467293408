<template>
  <transition name="viewer-fade">
    <div ref="video-viewer__wrapper" :style="{ 'z-index': viewerZIndex }" class="video-viewer__wrapper"
         tabindex="-1">
      <div class="video-viewer__mask" @click.self="handleMaskClick"></div>
      <!-- CLOSE -->
      <span class="video-viewer__btn video-viewer__close" @click="hide">
        <i class="el-icon-close"></i>
      </span>
      <!-- ARROW -->
      <template v-if="!isSingle">
        <span
            :class="{ 'is-disabled': !infinite && isFirst }"
            class="video-viewer__btn video-viewer__prev"
            @click="prev">
          <i class="el-icon-arrow-left"/>
        </span>
        <span
            :class="{ 'is-disabled': !infinite && isLast }"
            class="video-viewer__btn video-viewer__next"
            @click="next">
          <i class="el-icon-arrow-right"/>
        </span>
      </template>
      <div class="video-viewer__canvas">
        <div id="video-viewer-player"
             :style="videoStyle">
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { PopupManager } from "element-ui/src/utils/popup";

export default {
  name: "video-viewer",
  props: {
    current: {
      type: Number,
      default: 0
    },
    urlList: {
      type: Array,
      default: () => []
    },
    zIndex: {
      type: Number,
      default: 2000
    },
    onSwitch: {
      type: Function,
      default: () => {
      }
    },
    onClose: {
      type: Function,
      default: () => {
      }
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    maskClosable: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default() {
        return {
          rePlay: true,
          currentTime: 0
        }
      }
    }
  },
  computed: {
    isSingle() {
      return this.urlList.length <= 1;
    },
    isFirst() {
      return this.index === 0;
    },
    isLast() {
      return this.index === this.urlList.length - 1;
    },
    currentVideo() {
      return this.urlList[this.index];
    },
    videoStyle() {
      const style = {
        'margin-left': `0`,
        'margin-top': `0`,
        'width': '100% ',
        'height': ' 100% '
      };
      return style;
    },
    viewerZIndex() {
      const nextZIndex = PopupManager.nextZIndex();
      return this.zIndex > nextZIndex ? this.zIndex : nextZIndex;
    }
  },
  data() {
    return {
      index: this.current,
      isShow: false,
      infinite: true,
      loading: false,
      player: null
    }
  },
  mounted() {
    this.$refs['video-viewer__wrapper'].focus();
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
    this.handleVideoLoad();
  },
  destroyed() {
    // if appendToBody is true, remove DOM node after destroy
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    this.handleVideoDispose()
  },
  methods: {
    hide() {
      this.handleVideoDispose()
      this.onClose();
    },
    handleVideoLoad() {
      const { options } = this
      if (!this.player) {
        this.player = new Aliplayer({
          id: "video-viewer-player",
          width: "100%",
          height: "100%",
          autoplay: true,
          isLive: false,
          rePlay: true,
          playsinline: true,
          preload: true,
          controlBarVisibility: "hover",
          useH5Prism: true,
          ...options
        });
      }
      this.$nextTick(() => {
        this.player.loadByUrl(this.currentVideo)
        const { currentTime } = options
        if (currentTime && currentTime > 0) {
          this.player.seek(currentTime)
        }
      })
    },
    handleVideoDispose() {
      if (this.player) {
        this.player.dispose()
        this.player = null
      }
    },
    handleMaskClick() {
      if (this.maskClosable) {
        this.hide();
      }
    },
    prev() {
      if (this.isFirst && !this.infinite) return;
      const len = this.urlList.length;
      this.index = (this.index - 1 + len) % len;
    },
    next() {
      if (this.isLast && !this.infinite) return;
      const len = this.urlList.length;
      this.index = (this.index + 1) % len;
    },
  },
  watch: {
    index: {
      handler: function (val) {
        this.reset();
        this.onSwitch(val);
      }
    },
    currentVideo(val) {
      this.$nextTick(_ => {
        this.handleVideoLoad()
      });
    }
  }
}
</script>

<style scoped>

.video-viewer__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.video-viewer__btn {
  position: absolute;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  opacity: .8;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none
}

.el-button, .el-checkbox, .el-checkbox-button__inner, .el-empty__image img, .el-radio {
  -webkit-user-select: none
}

.video-viewer__close {
  top: 40px;
  right: 40px;
  width: 40px;
  height: 40px;
  font-size: 24px;
  color: #fff;
  background-color: #606266
}

.video-viewer__canvas {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.video-viewer__actions {
  left: 50%;
  bottom: 30px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 282px;
  height: 44px;
  padding: 0 23px;
  background-color: #606266;
  border-color: #fff;
  border-radius: 22px
}

.video-viewer__actions__inner {
  width: 100%;
  height: 100%;
  text-align: justify;
  cursor: default;
  font-size: 23px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around
}

.video-viewer__next, .video-viewer__prev {
  width: 44px;
  height: 44px;
  font-size: 24px;
  color: #fff;
  background-color: #606266;
  border-color: #fff;
  top: 50%
}

.video-viewer__prev {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 40px
}

.video-viewer__next {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 40px;
  text-indent: 2px
}

.video-viewer__mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /*opacity: .5;*/
  background: #000
}

.viewer-fade-enter-active {
  -webkit-animation: viewer-fade-in .3s;
  animation: viewer-fade-in .3s
}

.viewer-fade-leave-active {
  -webkit-animation: viewer-fade-out .3s;
  animation: viewer-fade-out .3s
}

@-webkit-keyframes viewer-fade-in {
  0% {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    opacity: 0
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@keyframes viewer-fade-in {
  0% {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    opacity: 0
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

@-webkit-keyframes viewer-fade-out {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
  100% {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    opacity: 0
  }
}

@keyframes viewer-fade-out {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
  100% {
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
    opacity: 0
  }
}
</style>